import { Me_me as User } from 'generated/Me';

class LocalStorageManager {
  getUser() {
    const raw = window.localStorage.getItem('user');
    if (raw && raw !== 'undefined') {
      const user = JSON.parse(raw);
      return user as User;
    } else {
      return;
    }
  }

  setUser(user: User) {
    window.localStorage.setItem('user', JSON.stringify(user));
  }

  removeUser() {
    window.localStorage.removeItem('user');
  }

  setToken(token: string) {
    window.localStorage.setItem('token', token);
  }

  getToken() {
    return window.localStorage.getItem('token');
  }

  removeToken() {
    window.localStorage.removeItem('token');
  }

  setRefreshToken(token: string) {
    return window.localStorage.setItem('refreshToken', token);
  }

  getRefreshToken() {
    return window.localStorage.getItem('refreshToken');
  }

  removeRefreshToken() {
    window.localStorage.removeItem('token');
  }

  getLanguage() {
    return window.localStorage.getItem('i18nextLng') ?? 'hu';
  }
}

export default new LocalStorageManager();
