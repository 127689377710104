import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactNode } from 'react';
import Box from '@mui/material/Box';
import { BoxProps } from '@mui/material/Box';

interface Props extends BoxProps {
  children: ReactNode;
  meta?: ReactNode;
  title: string;
}

const Page = forwardRef<HTMLDivElement, Props>(({ children, title = '', meta, ...other }, ref) => {
  return (
    <>
      <Helmet>
        <title>{`${title} | CTMS`}</title>
        {meta}
      </Helmet>

      <Box ref={ref} {...other}>
        {children}
      </Box>
    </>
  );
});

export default Page;
