import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useQueryParams = () => {
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const getParam = (key: string) => params.get(key) ?? null;

  const listQueryParams = (params: URLSearchParams): string => {
    if (!params) return '';

    const entries = Object.entries(params);
    if (!entries?.length) return '';

    let queryString = '?';
    for (const [key, value] of entries) {
      queryString += `${key}=${value}&`;
    }

    return queryString;
  };

  return {
    params,
    getParam,
    listQueryParams,
  };
};

export default useQueryParams;
