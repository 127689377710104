import { UserRole } from 'generated/globalTypes';
import useAuth from 'hooks/useAuth';
import { HTMLProps, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type AuthGuardProps = {
  permissions: UserRole[];
} & HTMLProps<HTMLDivElement>;

export default function RoleBasedGuard(props: AuthGuardProps): JSX.Element {
  const [isLoading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    setLoading(true);

    if (!user?.role || !props.permissions.includes(user?.role)) {
      navigate('/500');
    } else {
      setLoading(false);
    }
  }, [user, navigate, props.permissions]);

  return <>{!isLoading && props.children}</>;
}
