import { useQuery } from '@apollo/client';
import { ListNotifications, ListNotificationsVariables } from 'generated/ListNotifications';
import { NOTIFICATIONS_QUERY } from 'gql/Notification';
import React, { createContext, ReactNode, useEffect } from 'react';
import { NotificationContextValues } from 'types/ctms/Notification';

export const NotificationContext = createContext<NotificationContextValues>({ notifications: [], total: 0 });

export default function NotificationProvider({ children }: { children: ReactNode }) {
  const { data, refetch } = useQuery<ListNotifications, ListNotificationsVariables>(NOTIFICATIONS_QUERY, {
    variables: { onlyUnseens: true },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    refetch();
  }, [children, refetch]);

  const value = {
    notifications: data?.notifications?.items ?? [],
    total: data?.notifications?.total ?? 0,
  };

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
}
