import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import { BoxProps } from '@mui/material/Box';

import ctmsLogo from '../assets/logos/korkeplogo.png';
import ctmsLogoSmall from '../assets/logos/korkeplogo-small.png';

interface Props extends BoxProps {
  disabledLink?: boolean;
  small?: boolean;
}

export default function Logo({ disabledLink = false, sx, small }: Props) {
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      {small ? <img src={ctmsLogoSmall} alt="ctms-logo-small" /> : <img src={ctmsLogo} alt="ctms-logo" />}
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
