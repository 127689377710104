export const loadMomentLocale = async (lang: string) => {
  // Requires a switch because dynamic imports don't support string interpolation
  switch (lang) {
    case 'en':
      await import('./localesReExport/en');
      break;
    default:
      await import('./localesReExport/hu');
  }
};
