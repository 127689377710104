import { gql } from '@apollo/client';

export const FULL_ADDRESS_FRAGMENT = gql`
  fragment FullAddress on Address {
    zipcode
    town
    street
    number
  }
`;

export const FULL_NAME_FRAGMENT = gql`
  fragment PersonName on PersonName {
    last
    first
    prefix
  }
`;

export const CONTACT_PERSON_FRAGMENT = gql`
  ${FULL_NAME_FRAGMENT}

  fragment BaseContactPerson on ContactPerson {
    id
    name {
      ...PersonName
    }
    type
    email
    phone
    imagingVendor {
      id
      name
    }
    cro {
      id
      name
    }
    payer {
      id
      name
    }
    sponsor {
      id
      name
    }
    site {
      id
      name
    }
  }
`;

export const ORGANIZATION_FRAGMENT = gql`
  fragment Organization on Organization {
    id
    name
  }
`;

export const LOCATION_FRAGMENT = gql`
  fragment Location on Location {
    id
    name
  }
`;

export const SERVICE_FRAGMENT = gql`
  fragment Service on Service {
    id
    name
  }
`;
