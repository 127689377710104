import { GridLocaleText } from '@mui/x-data-grid';

const gridLocale: GridLocaleText = {
  filterOperatorIsAnyOf: '',
  // Root
  noRowsLabel: 'Nincs tartalom.',
  noResultsOverlayLabel: 'Nincs találat.',
  errorOverlayDefaultLabel: 'Hiba történt.',

  // Density selector toolbar button text
  toolbarDensity: 'Térköz',
  toolbarDensityLabel: 'Térköz',
  toolbarDensityCompact: 'Kompakt',
  toolbarDensityStandard: 'Normál',
  toolbarDensityComfortable: 'Kényelmes',

  // Columns selector toolbar button text
  toolbarColumns: 'Oszlopok',
  toolbarColumnsLabel: 'Oszlopok kiválasztása',

  // Filters toolbar button text
  toolbarFilters: 'Szűrők',
  toolbarFiltersLabel: 'Szűrők mutatása',
  toolbarFiltersTooltipHide: 'Szűrők elrejtése',
  toolbarFiltersTooltipShow: 'Szűrők megjelenítése',
  toolbarFiltersTooltipActive: (count) => (count !== 1 ? `${count} aktív szűrő` : `${count} aktív szűrő`),

  // Export selector toolbar button text
  toolbarExport: 'Exportálás',
  toolbarExportLabel: 'Exportálás',
  toolbarExportCSV: 'Letöltés CSV-be',
  toolbarExportPrint: 'Nyomtatás',

  // Columns panel text
  columnsPanelTextFieldLabel: 'Oszlop keresés',
  columnsPanelTextFieldPlaceholder: 'Oszlop neve',
  columnsPanelDragIconLabel: 'Oszlop sorrend módosítása',
  columnsPanelShowAllButton: 'Összes megjelenítése',
  columnsPanelHideAllButton: 'Összes elrejtése',

  // Filter panel text
  filterPanelAddFilter: 'Szűrő hozzáadása',
  filterPanelDeleteIconLabel: 'Delete',
  filterPanelOperators: 'Operators',
  filterPanelOperatorAnd: 'And',
  filterPanelOperatorOr: 'Or',
  filterPanelColumns: 'Columns',
  filterPanelInputLabel: 'Value',
  filterPanelInputPlaceholder: 'Filter value',
  filterPanelLinkOperator: '',

  // Detail panel
  expandDetailPanel: 'Expand Panel',
  collapseDetailPanel: 'Collapse Panel',

  // Filter operators text
  filterOperatorContains: 'contains',
  filterOperatorEquals: 'equals',
  filterOperatorStartsWith: 'starts with',
  filterOperatorEndsWith: 'ends with',
  filterOperatorIs: 'is',
  filterOperatorNot: 'is not',
  filterOperatorAfter: 'is after',
  filterOperatorOnOrAfter: 'is on or after',
  filterOperatorBefore: 'is before',
  filterOperatorOnOrBefore: 'is on or before',
  filterOperatorIsEmpty: 'is empty',
  filterOperatorIsNotEmpty: 'is not empty',

  // Filter values text
  filterValueAny: 'any',
  filterValueTrue: 'true',
  filterValueFalse: 'false',

  // Column menu text
  columnMenuLabel: 'Menü',
  columnMenuShowColumns: 'Oszlopok kezelése',
  columnMenuFilter: 'Szűrés',
  columnMenuHideColumn: 'Elrejt',
  columnMenuUnsort: 'Unsort',
  columnMenuSortAsc: 'Növekvő sorrend',
  columnMenuSortDesc: 'Csökkenő sorrend',

  // Column header text
  columnHeaderFiltersTooltipActive: (count) => (count !== 1 ? `${count} active filters` : `${count} active filter`),
  columnHeaderFiltersLabel: 'Show filters',
  columnHeaderSortIconLabel: 'Sort',

  // Rows selected footer text
  footerRowSelected: (count) =>
    count !== 1 ? `${count.toLocaleString()} rows selected` : `${count.toLocaleString()} row selected`,

  // Total rows footer text
  footerTotalRows: 'Összes sor:',

  // Total visible rows footer text
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,

  // Checkbox selection text
  checkboxSelectionHeaderName: 'Jelölő négyzetek',
  checkboxSelectionSelectAllRows: '',
  checkboxSelectionUnselectAllRows: '',
  checkboxSelectionSelectRow: '',
  checkboxSelectionUnselectRow: '',

  // Boolean cell text
  booleanCellTrueLabel: 'igaz',
  booleanCellFalseLabel: 'hamis',

  // Actions cell more text
  actionsCellMore: 'bővebbben',

  // Column pinning text
  pinToLeft: 'Pin to left',
  pinToRight: 'Pin to right',
  unpin: 'Unpin',

  // Tree Data
  treeDataGroupingHeaderName: 'Group',
  treeDataExpand: 'see children',
  treeDataCollapse: 'hide children',

  // Grouping columns
  groupingColumnHeaderName: 'Group',
  groupColumn: (name) => `Group by ${name}`,
  unGroupColumn: (name) => `Stop grouping by ${name}`,

  // Used core components translation keys
  MuiTablePagination: {},
};

export default gridLocale;
