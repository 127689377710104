import { huHU } from '@mui/material/locale';

import { alpha, createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { ReactNode, useMemo } from 'react';
import componentsOverrides from 'theme/overrides';
import useSettings from '../hooks/useSettings';

type Props = {
  children: ReactNode;
};

export default function ThemeColorPresets({ children }: Props) {
  const defaultTheme = useTheme();
  const { setColor } = useSettings();

  const themeOptions = useMemo(
    () => ({
      ...defaultTheme,
      palette: {
        ...defaultTheme.palette,
        primary: setColor,
      },
      customShadows: {
        ...defaultTheme.customShadows,
        primary: `0 8px 16px 0 ${alpha(setColor.main, 0.24)}`,
      },
    }),
    [setColor, defaultTheme]
  );

  const theme = createTheme(themeOptions, huHU);
  theme.components = componentsOverrides(theme);

  // Manual fix for localization
  // Minimals' `ComponentsOverrides` overrides the `defaultProps` from huHU Localization
  if (theme.components) {
    theme.components.MuiAlert = Object.assign(theme.components.MuiAlert, huHU.components?.MuiAlert);
    theme.components.MuiBreadcrumbs = Object.assign(theme.components.MuiBreadcrumbs, huHU.components?.MuiBreadcrumbs);
    theme.components.MuiRating = Object.assign(theme.components.MuiRating, huHU.components?.MuiRating);
    theme.components.MuiAutocomplete = Object.assign(
      theme.components.MuiAutocomplete,
      huHU.components?.MuiAutocomplete
    );
    theme.components.MuiTablePagination = Object.assign(
      theme.components.MuiTablePagination,
      huHU.components?.MuiTablePagination
    );

    // Minimals doesn't override MuiPagination
    // theme.components.MuiPagination = Object.assign(theme.components.MuiPagination, huHU.components?.MuiPagination);
  }

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
