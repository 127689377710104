import { GridSortDirection, GridSortModel, GridToolbar } from '@mui/x-data-grid';
import config from 'config';
import { OrderType } from 'generated/globalTypes';
import { useState } from 'react';
import { ServerPaginatedDataGridInputType } from 'types/ctms/DataGridTypes';
import dataGridLocale from '../../i18n/DataGridLocale';
import { HeaderAndRowHeightAdjustedDataGrid } from './StyledDataGrids';

const getSortModel = (model: GridSortModel) => {
  const order: Record<string, OrderType> = {};
  model.forEach((model) => {
    order[model.field] = model.sort === 'asc' ? OrderType.ASC : OrderType.DESC;
  });
  return order;
};

const ServerPaginatedDataGrid = ({
  rows,
  columns,
  total,
  loading,
  fetchMore,
  listArgs,
  modifiedStyles,
  rowsPerPageOptions = [],
  defaultPageSize,
  getRowId,
}: ServerPaginatedDataGridInputType): JSX.Element => {
  const [size, setSize] = useState(defaultPageSize ?? config.defaultSize);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const handleSortModelChange = async (model: GridSortModel) => {
    setSortModel(model);
    fetchMore({
      variables: {
        args: {
          filter: listArgs.filter,
          order: getSortModel(model),
          take: size,
        },
      },
    });
  };

  return (
    <HeaderAndRowHeightAdjustedDataGrid
      sortingMode="server"
      initialState={{
        sorting: {
          sortModel: Object.entries(listArgs.order).map(([v, key]) => {
            return { field: key as string, sort: v as GridSortDirection };
          }),
        },
      }}
      sortModel={sortModel}
      onSortModelChange={handleSortModelChange}
      componentsProps={{
        toolbar: {
          csvOptions: { disableToolbarButton: true },
          printOptions: { disableToolbarButton: true },
        },
      }}
      disableColumnFilter
      loading={loading}
      components={{ Toolbar: GridToolbar }}
      autoHeight
      localeText={dataGridLocale}
      rows={rows}
      columns={columns}
      pageSize={size}
      getRowId={getRowId}
      paginationMode="server"
      rowCount={total}
      onPageSizeChange={async (pageSize) => {
        setSize(pageSize);
        await fetchMore({
          variables: {
            args: {
              ...listArgs,
              page: 0,
              take: pageSize,
            },
          },
        });
      }}
      onPageChange={async (page) => {
        await fetchMore({
          variables: {
            args: {
              ...listArgs,
              order: getSortModel(sortModel),
              page,
              take: size,
            },
          },
        });
      }}
      rowsPerPageOptions={rowsPerPageOptions?.length ? rowsPerPageOptions : config.rowsPerPageOptions}
      disableSelectionOnClick
      sx={modifiedStyles}
    />
  );
};

export default ServerPaginatedDataGrid;
