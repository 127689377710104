import { useSnackbar as useNotiSnackbar } from 'notistack';
import { MIconButton } from 'components/@material-extend';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { SnackbarType } from 'enums/SnackbarType';

const useSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useNotiSnackbar();

  const enqueueSnackbarWithTypeAndMessage = ({ message, type }: { message: string; type: SnackbarType }) => {
    enqueueSnackbar(message, {
      variant: type,
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      ),
    });
  };

  return {
    enqueueSnackbarWithTypeAndMessage,
  };
};

export default useSnackbar;
