import { createContext, ReactNode, useState } from 'react';
import { TitleContextType } from 'types/ctms/Navbar';

const defaultItems: string[] = [];

export const TitleContext = createContext<TitleContextType>({ menuItems: defaultItems, setMenuItems: () => {} });

export default function TitleProvider({ children }: { children: ReactNode }) {
  const [menuItems, setMenuItems] = useState<string[]>(defaultItems);
  return <TitleContext.Provider value={{ menuItems, setMenuItems }}>{children}</TitleContext.Provider>;
}
