import { gql } from '@apollo/client';
import { FULL_ADDRESS_FRAGMENT, FULL_NAME_FRAGMENT } from 'fragments/Fragments';

export const ME_QUERY = gql`
  query Me {
    me {
      id
      name {
        # ...PersonName
        prefix
        last
        first
      }
      created
      cv {
        id
        name
      }
      email
      organization {
        name
        id
      }
      personalEmail
      personalPhone
      phone
      role
      services {
        id
        name
      }
    }
  }
`;

export const USERS_LIST = gql`
  ${FULL_NAME_FRAGMENT}

  query UserList($args: UserListingArg!) {
    users(listArg: $args) {
      items {
        id
        status
        name {
          ...PersonName
        }
        role
        organization {
          name
        }
        email
        phone
        personalEmail
        personalPhone
      }
      total
      downloadToken
    }
  }
`;

export const GET_USER = gql`
  ${FULL_NAME_FRAGMENT}
  ${FULL_ADDRESS_FRAGMENT}

  query UserGet($id: String!, $notesAllowed: Boolean!) {
    userContracts(listArgs: { filter: { userId: $id } }) {
      items {
        address {
          ...FullAddress
        }
        bankAccount
        contractType
        document {
          id
          name
        }
        id
        name
        organization {
          id
          name
        }
        validUntil
        vatNumber
      }
    }
    user(id: $id) {
      id
      status
      name {
        ...PersonName
      }
      created
      cv {
        id
        name
      }
      gcp {
        id
        name
      }
      location {
        id
        name
      }
      gcpSigned
      email
      organization {
        name
        id
      }
      personalEmail
      personalPhone
      phone
      role
      notes @include(if: $notesAllowed)
      services {
        id
        name
      }
    }
  }
`;

export const USER_DELETE = gql`
  mutation DeleteUser($id: String!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

export const CREATE_USER = gql`
  mutation UserAdd($user: CreateUserInput!) {
    createUser(user: $user) {
      id
    }
  }
`;

export const SEND_ACTIVATION = gql`
  mutation SendActivationEmail($id: String!) {
    activateUser(id: $id) {
      id
    }
  }
`;

export const RESET_TFA = gql`
  mutation ResetTfa($id: String!) {
    resetTfa(id: $id)
  }
`;

export const UPDATE_USER = gql`
  ${FULL_NAME_FRAGMENT}

  mutation UserUpdate($user: UpdateUserInput!, $notesAllowed: Boolean!) {
    updateUser(user: $user) {
      id
      status
      name {
        ...PersonName
      }
      created
      cv {
        id
        name
      }
      gcp {
        id
        name
      }
      gcpSigned
      email
      notes @include(if: $notesAllowed)
      organization {
        name
        id
      }
      personalEmail
      personalPhone
      phone
      role
      services {
        id
        name
      }
    }
  }
`;

export const USER_UPLOAD_FILE = gql`
  mutation UserFileUpload($input: UserDocumentInput!) {
    uploadUserDocument(input: $input) {
      id
      name
    }
  }
`;

// UserContract
export const USER_CONTRACT_DELETE = gql`
  mutation DeleteUserContract($id: String!) {
    deleteUserContract(id: $id) {
      id
    }
  }
`;

export const CREATE_USER_CONTRACT = gql`
  ${FULL_ADDRESS_FRAGMENT}

  mutation UserContractAdd($userContract: CreateUserContractInput!) {
    createUserContract(userContract: $userContract) {
      id
      name
      contractType
      bankAccount
      vatNumber
      address {
        ...FullAddress
      }
      document {
        id
        name
      }
      organization {
        id
        name
      }
      validUntil
    }
  }
`;

export const UPDATE_USER_CONTRACT = gql`
  ${FULL_ADDRESS_FRAGMENT}

  mutation UserContractUpdate($userContract: UpdateUserContractInput!) {
    updateUserContract(userContract: $userContract) {
      id
      name
      contractType
      bankAccount
      vatNumber
      address {
        ...FullAddress
      }
      document {
        id
        name
      }
      organization {
        id
        name
      }
      validUntil
    }
  }
`;

export const USER_CONTRACT_UPLOAD_FILE = gql`
  mutation UserContractFileUpload($userContractDocument: UserContractDocumentInput!) {
    uploadUserContractDocument(userContractDocument: $userContractDocument) {
      id
      name
    }
  }
`;

export const UPDATE_USER_STATUS = gql`
  mutation UpdateUserStatusChange($input: UpdateUserStatus!) {
    updateUserStatus(input: $input) {
      id
      status
    }
  }
`;
