import Container from "@mui/material/Container";
import { styled } from '@mui/material/styles';
import { memo } from 'react';
import { NavSectionHorizontal } from '../../../components/nav-section';
import { HEADER } from '../../../config/layoutConfig';
import GetSidebarConfig from './NavConfig';

const RootStyle = styled('div')(({ theme }) => ({
  transition: theme.transitions.create('top', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  width: '100%',
  position: 'fixed',
  zIndex: theme.zIndex.appBar,
  padding: theme.spacing(1, 0),
  boxShadow: theme.customShadows.z8,
  top: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  backgroundColor: theme.palette.background.default,
}));

function NavbarHorizontal() {
  const config = GetSidebarConfig();
  return (
    <RootStyle>
      <Container maxWidth={false}>{config && <NavSectionHorizontal navConfig={config} />}</Container>
    </RootStyle>
  );
}

export default memo(NavbarHorizontal);
