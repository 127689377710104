import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import config from 'config/config';
import { ContactPersonType, CreateContactPersonInput } from 'generated/globalTypes';
import useAuth from 'hooks/useAuth';
import useLocales from 'hooks/useLocales';
import { Controller, Resolver, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { ContactPersonFormInput, ContactPersonFormProps } from 'types/ctms/ContactPersonTypes';

export default function ContactPersonForm({
  contactPersonParentIds,
  onCreate,
  onUpdate,
  currentEntity,
}: ContactPersonFormProps): JSX.Element {
  const { translate } = useLocales();
  const { user } = useAuth();

  let selectableContactTypes: ContactPersonType[] = [];
  if (contactPersonParentIds?.siteId) {
    selectableContactTypes = [ContactPersonType.PRINCIPAL_INVESTIGATOR, ContactPersonType.COORDINATOR];
  } else {
    selectableContactTypes = [
      ContactPersonType.FINANCE,
      ContactPersonType.COORDINATOR,
      ContactPersonType.MONITOR,
      ContactPersonType.OTHER,
      ContactPersonType.CRA,
      ContactPersonType.BUDGET,
      ContactPersonType.CONTRACT,
    ];
  }

  const ContactPersonSchema = Yup.object().shape({
    name: Yup.object({
      last: Yup.string().required(translate('validation.lastname')),
      first: Yup.string().required(translate('validation.firstname')),
    }),
    email: Yup.string().email(translate('validation.emailFormat')).required(translate('validation.email')),
    personalEmail: Yup.string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .email(translate('validation.emailFormat')),
    personalPhone: Yup.string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(config.regex.anyPhoneNumber, translate('validation.phoneNumberMatch')),
    phone: Yup.string()
      .required(translate('validation.phone'))
      .matches(config.regex.anyPhoneNumber, translate('validation.phoneNumberMatch')),
    type: Yup.mixed().oneOf(Object.values(selectableContactTypes), translate('validation.type')),
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<ContactPersonFormInput>({
    defaultValues: currentEntity,
    shouldUnregister: true,
    resolver: yupResolver(ContactPersonSchema) as Resolver<ContactPersonFormInput>,
  });

  const onSubmit = async (data: CreateContactPersonInput) => {
    if (currentEntity?.id) {
      if (!onUpdate) return;
      await onUpdate({ id: currentEntity.id, ...data, ...contactPersonParentIds });
    } else {
      if (!onCreate) return;
      await onCreate({ ...data, ...contactPersonParentIds });
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Controller
            name="name.prefix"
            control={control}
            defaultValue=""
            render={({ field }) => <TextField {...field} fullWidth label={translate('form.prefix')} />}
          />
          <Controller
            name="name.last"
            control={control}
            defaultValue=""
            render={({ field: { ...field }, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label={translate('form.lastname')}
                error={error?.message ? true : false}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name="name.first"
            control={control}
            defaultValue=""
            render={({ field: { ...field }, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label={translate('form.firstname')}
                error={error?.message ? true : false}
                helperText={error?.message}
              />
            )}
          />
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={translate('form.email')}
                error={errors.email && true}
                helperText={errors.email?.message}
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={translate('form.phone')}
                error={errors.phone && true}
                helperText={errors.phone?.message}
              />
            )}
          />
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Controller
            name="personalEmail"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={translate('form.personalEmail')}
                error={errors.personalEmail && true}
                helperText={errors.personalEmail?.message}
              />
            )}
          />
          <Controller
            name="personalPhone"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={translate('form.personalPhone')}
                error={errors.personalPhone && true}
                helperText={errors.personalPhone?.message}
              />
            )}
          />
        </Stack>
        <Controller
          control={control}
          name="type"
          defaultValue={ContactPersonType.COORDINATOR}
          render={({ field: { onChange, value } }) => (
            <TextField select fullWidth onChange={onChange} value={value} label={translate('form.type')}>
              {selectableContactTypes.map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    {translate(`enums.contactPersonType.${option}`)}
                  </MenuItem>
                );
              })}
            </TextField>
          )}
        />
        {user?.role && config.managerAdminSys.includes(user.role) && (
          <Controller
            name="notes"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField {...field} fullWidth label={translate('form.notes')} multiline maxRows={4} minRows={4} />
            )}
          />
        )}
        {onCreate && (
          <Box display="flex" justifyContent="flex-end">
            <LoadingButton
              variant="contained"
              loading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
              sx={{ textTransform: 'uppercase' }}
            >
              {currentEntity?.id ? translate('save') : translate('add')}
            </LoadingButton>
          </Box>
        )}
      </Stack>
    </>
  );
}
