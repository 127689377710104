import { MotionContainer } from 'components/animate';
import NotistackProvider from 'components/NotistackProvider';
import TitleProvider from 'contexts/TitleContext';
import useLocales from 'hooks/useLocales';
import { loadMomentLocale } from 'i18n/loadLocaleModules';
import { useEffect } from 'react';
import { ProgressBarStyle } from './components/ProgressBar';
import ScrollToTop from './components/ScrollToTop';
import Settings from './components/settings';
import ThemeColorPresets from './components/ThemeColorPresets';
import Router from './routes';
import ThemeProvider from './theme';
import markerSDK from '@marker.io/browser';

export default function App() {
  const { currentLang } = useLocales();

  useEffect(() => {
    if (currentLang.value) loadMomentLocale(currentLang.value);
    if (process.env.REACT_APP_ENV === 'test') {
      markerSDK.loadWidget({ project: '64bfe5ceccdda9a3a2f853d7', source: 'snippet' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <NotistackProvider>
          <TitleProvider>
            <MotionContainer>
              <ProgressBarStyle />
              <Settings />
              <ScrollToTop />
              <Router />
            </MotionContainer>
          </TitleProvider>
        </NotistackProvider>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
