import useAuth from '../hooks/useAuth';
import createAvatar from '../utils/createAvatar';
import MAvatar, { MAvatarProps } from './@material-extend/MAvatar';

export default function MyAvatar({ ...other }: MAvatarProps) {
  const { user } = useAuth();

  return (
    <MAvatar
      alt={user?.name.last + '' + user?.name.first}
      color={createAvatar(user?.name.last || 'A').color}
      {...other}
    >
      {createAvatar(user?.name.last || 'A').name}
    </MAvatar>
  );
}
