import { UserRole } from 'generated/globalTypes';

const env =
  process.env.REACT_APP_ENV === 'development' ||
  process.env.REACT_APP_ENV === 'production' ||
  process.env.REACT_APP_ENV === 'test'
    ? process.env.REACT_APP_ENV
    : 'development';

const baseUrl = 'http://localhost:3333';
const testBaseUrl = 'https://ctms-api.dtapp.hu';
const prodBaseUrl = 'https://ctms-api.kepalkotas.hu';

const FEATURE_FLAGS = {
  TWO_FACTOR_AUTH_ENABLED: false,
};

const config = {
  default: {
    apiEndpoint: `${baseUrl}/graphql`,
    loginEndpoint: `${baseUrl}/login`,
    refreshEndpoint: `${baseUrl}/login/refresh`,
    tfaCodeEndpoint: `${baseUrl}/tfa/verify-topt`,
    tfaQrCodeEndpoint: `${baseUrl}/tfa/qr-code`,
    tfaOneTimeCodeEndpoint: `${baseUrl}/tfa/get-one-time-access-code`,
    passwordResetEndpoint: `${baseUrl}/reset-password`,
    registrationEndpoint: `${baseUrl}/registration/activate`,
    getUserWithTokenEndpoint: `${baseUrl}/registration/get-user-with-token`,
    exportEndpoint: `${baseUrl}/export`,
    regex: {
      anyPhoneNumber: /^(\+\d{7,})$/,
      addressNumber: /^[-,A-Za-z\d\s./]{1,}$/,
      hungarianBankAccountNumber: /^[0-9]{8}-[0-9]{8}-[0-9]{8}$/,
      hungarianVatNumber: /^[0-9]{8}-[0-9]{1}-[0-9]{2}$/,
      hungarianVatId: /^[0-9]{10}$/,
      hungarianPhone:
        /^(?:(\+?(?:36|\(36\)))|06)[ -/]?(?:(?:(?:(?!1|20|21|30|31|70|90)[2-9][0-9])[ -/]?\d{3}[ -/]?\d{3})|(?:(?:1|20|21|30|31|70|90)[ -/]?\d{3}[ -/]?\d{2}[ -/]?\d{2}))$/,
      digitsOnly: /^[0-9]{0,}$/,
      hungarianCompanyRegistryNumber: /^[0-9]{2}-[0-9]{2}-[0-9]{6}$/,
      passwordRequirements: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_.,-])[A-Za-z\d@$!%*#?&_.,-]{8,}$/,
      hungarianSocialSecurityNumber: /^(\d{9})|(\d{3}-\d{3}-\d{3})$/,
    },
    uploadLimit: 20000000,
    uploadType: ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'],
    uploadWordMimeTypes: [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ],
    rowsPerPageOptions: [10, 20, 50, 100],
    defaultPage: 0,
    defaultSize: 100,
    managerAdminSys: [UserRole.MANAGER, UserRole.ADMIN, UserRole.SYSTEM_ADMIN],
    reportingAdmins: [
      UserRole.MANAGER,
      UserRole.ADMIN,
      UserRole.SYSTEM_ADMIN,
      UserRole.COORDINATION_ADMIN,
      UserRole.FINANCIAL_ADMIN,
    ],
    operatorDoctor: [UserRole.OPERATOR, UserRole.DOCTOR],
    allAdmins: [
      UserRole.ADMIN,
      UserRole.SYSTEM_ADMIN,
      UserRole.COORDINATION_ADMIN,
      UserRole.FINANCIAL_ADMIN,
      UserRole.STARTUP_ADMIN,
    ],
    systemLaunchDate: new Date('2019-09-01'),
  },
  development: {},
  test: {
    apiEndpoint: `${testBaseUrl}/graphql`,
    loginEndpoint: `${testBaseUrl}/login`,
    refreshEndpoint: `${testBaseUrl}/login/refresh`,
    tfaCodeEndpoint: `${testBaseUrl}/tfa/verify-topt`,
    tfaQrCodeEndpoint: `${testBaseUrl}/tfa/qr-code`,
    tfaOneTimeCodeEndpoint: `${testBaseUrl}/tfa/get-one-time-access-code`,
    passwordResetEndpoint: `${testBaseUrl}/reset-password`,
    registrationEndpoint: `${testBaseUrl}/registration/activate`,
    getUserWithTokenEndpoint: `${testBaseUrl}/registration/get-user-with-token`,
    exportEndpoint: `${testBaseUrl}/export`,
  },
  production: {
    apiEndpoint: `${prodBaseUrl}/graphql`,
    loginEndpoint: `${prodBaseUrl}/login`,
    refreshEndpoint: `${prodBaseUrl}/login/refresh`,
    tfaCodeEndpoint: `${prodBaseUrl}/tfa/verify-topt`,
    tfaQrCodeEndpoint: `${prodBaseUrl}/tfa/qr-code`,
    tfaOneTimeCodeEndpoint: `${prodBaseUrl}/tfa/get-one-time-access-code`,
    passwordResetEndpoint: `${prodBaseUrl}/reset-password`,
    registrationEndpoint: `${prodBaseUrl}/registration/activate`,
    getUserWithTokenEndpoint: `${prodBaseUrl}/registration/get-user-with-token`,
    exportEndpoint: `${prodBaseUrl}/export`,
  },
};

export default { ...config.default, ...config[env as keyof typeof config], ...FEATURE_FLAGS } as typeof config.default &
  typeof FEATURE_FLAGS;
