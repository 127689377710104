import config from 'config';
import { UserRole } from 'generated/globalTypes';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated, user } = useAuth();

  if (isAuthenticated && user) {
    if ([...config.allAdmins, UserRole.MANAGER].includes(user.role)) {
      return <Navigate to={'/dashboard'} />;
    }

    if ([UserRole.OPERATOR, UserRole.DOCTOR].includes(user.role)) {
      return <Navigate to={'/participant-examinations'} />;
    }

    return <Navigate to={'/'} />;
  }

  return <>{children}</>;
}
