import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import MomentDateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import * as Sentry from '@sentry/react';
import { createUploadLink } from 'apollo-upload-client';
import i18n from 'i18n/i18n';
import moment from 'moment';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { BrowserRouter } from 'react-router-dom';
import LocalStorageManager from 'utils/LocalStorageManager';
import { handleAuthenticationError, promiseToObservable } from 'utils/authorization';
import App from './App';
import config from './config/';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { SettingsProvider } from './contexts/SettingsContext';

import 'moment/locale/hu';
import 'simplebar/src/simplebar.css';

i18n;

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = LocalStorageManager.getToken();
    const ownHeaders: Record<string, string> = {};
    if (token) {
      ownHeaders.authorization = `Bearer ${token}`;
    }
    return {
      headers: {
        ...headers,
        ...ownHeaders,
      },
    };
  });

  return forward(operation);
});

const uploadLink = createUploadLink({ uri: config.apiEndpoint });

const errorLink = onError(({ graphQLErrors, networkError, forward, operation }) => {
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }

  if (graphQLErrors) {
    for (const { message, locations, path, extensions } of graphQLErrors) {
      if (extensions.code === 'UNAUTHENTICATED') {
        return promiseToObservable(handleAuthenticationError()).flatMap(() => forward(operation));
      }
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    }
  }
});

const client = new ApolloClient({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  link: ApolloLink.from([errorLink, authMiddleware, uploadLink]),
  cache: new InMemoryCache({
    typePolicies: {
      // PAGINATED ENTITIES
      PaginatedOrganizations: { keyFields: [] },
      PaginatedUsers: { keyFields: [] },
      PaginatedSites: { keyFields: [] },
      PaginatedLocations: { keyFields: [] },
      PaginatedSponsors: { keyFields: [] },
      PaginatedCROs: { keyFields: [] },
      PaginatedImagingVendors: { keyFields: [] },
      PaginatedPayers: { keyFields: [] },
      PaginatedDevices: { keyFields: [] },
      PaginatedServices: { keyFields: [] },
      PaginatedContactPersons: { keyFields: [] },
      PaginatedProposals: { keyFields: [] },
      PaginatedProposalContracts: { keyFields: [] },
      PaginatedStudies: { keyFields: [] },
      PaginatedStudiesWithoutDownloadToken: { keyFields: [] },
      PaginatedProposalServices: { keyFields: [] },
      PaginatedProposalSites: { keyFields: [] },
      PaginatedPatients: { keyFields: [] },
      PaginatedExaminations: { keyFields: [] },
      PaginatedPayoffs: { keyFields: [] },
      PaginatedPayoffItems: { keyFields: [] },
      PaginatedPayoffInvoices: { keyFields: [] },
      PaginatedPayoff: { keyFields: [] },
      PaginatedVisitTypes: { keyFields: [] },
      PaginatedStudyParticipants: { keyFields: [] },
      PaginatedStudyDevices: { keyFields: [] },
      PaginatedUserContracts: { keyFields: [] },
      PaginatedCommissionRecipient: { keyFields: [] },
      PaginatedTechnicalParticipantsFinancialReport: { keyFields: [] },
      PaginatedHeadOfDepartmentFinancialReport: { keyFields: [] },
      // CONNECTED ENTITIES
      ProposalService: { keyFields: false },
      FinancialDistributionTemplate: { keyFields: false },
      PaginatedSponsorsFinancialReport: { keyFields: [] },
      PaginatedParticipantsFinancialReport: { keyFields: [] },
      PaginatedSitesFinancialReport: { keyFields: [] },
      PaginatedPayoffsFinancialReport: { keyFields: [] },
      PaginatedServicesFinancialReport: { keyFields: [] },
      PaginatedPatientCoordinationReport: { keyFields: [] },
      PaginatedPrincipalInvestigatorOrSiteCoordinationReport: { keyFields: [] },
      PaginatedDeviceCoordinationReport: { keyFields: [] },
      PaginatedDoctorOrOperatorCoordinationReport: { keyFields: [] },
      PaginatedLocationCoordinationReport: { keyFields: [] },
      PaginatedImagingVendorCoordinationReport: { keyFields: [] },
      PaginatedOrgCoordinationReport: { keyFields: [] },
      PaginatedCroOrSponsorCoordinationReport: { keyFields: [] },
      PaginatedStartupCoordinationReport: { keyFields: [] },
      PaginatedStudyLoadCoordinationReport: { keyFields: [] },
      PaginatedProposalSiteCoordinationReport: { keyFields: [] },
      PaginatedStudiesCoordinationReport: { keyFields: [] },
      PaginatedStudyRelatedEntityCoordinationReportResponse: { keyFields: [] },
      PaginatedParticipantsFinancialPeriodicReport: { keyFields: [] },
    },
  }),
  connectToDevTools: true,
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    watchQuery: {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      errorPolicy: 'all',
    },
  },
});

if (process.env.REACT_APP_ENV === 'production' && process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.render(
  <ApolloProvider client={client}>
    <LocalizationProvider dateAdapter={MomentDateAdapter} locale={moment.locale()}>
      <HelmetProvider>
        <SettingsProvider>
          <CollapseDrawerProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </CollapseDrawerProvider>
        </SettingsProvider>
      </HelmetProvider>
    </LocalizationProvider>
  </ApolloProvider>,
  document.getElementById('root')
);
