import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// @mui
import { alpha } from '@mui/material/styles';
import MyAvatar from 'components/MyAvatar';
import useAuth from 'hooks/useAuth';
import useLocales from 'hooks/useLocales';
import { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import formatFullName from 'utils/formatFullName';
import { IconButtonAnimate } from '../../../components/animate';
// components
import MenuPopover from '../../../components/MenuPopover';

const MENU_OPTIONS = [
  {
    label: 'Saját profil',
    linkTo: '/profile',
  },
];

export default function AccountPopover() {
  const { translate } = useLocales();
  const { logout } = useAuth();
  const auth = useAuth();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography
            variant="subtitle2"
            sx={{
              width: '165px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {auth?.user?.name &&
              formatFullName({
                prefix: auth.user.name.prefix,
                last: auth.user.name.last,
                first: auth.user.name.first,
              })}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={handleClose} component={RouterLink} to={`/profile`}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem sx={{ m: 1 }} onClick={() => logout()}>
          {translate('logout')}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
