import { DatePicker } from '@mui/lab';
import { IconButton, InputAdornment } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { SearchFieldAll, SearchFieldType } from 'enums/SearchEnums';
import useLocales from 'hooks/useLocales';
import moment from 'moment';
import { SearchFieldConfig } from 'types/ctms/SearchTypes';
import useTheme from '@mui/material/styles/useTheme';
import { ReactNode } from 'react';

const ListingPageSearchInputs = ({ searchFields }: { searchFields: SearchFieldConfig[] }): JSX.Element => {
  const theme = useTheme();
  const { translate } = useLocales();

  const getOptionLabel = (enumName: string, option: string) => {
    if (option === SearchFieldAll.ALL) return translate(`enums.${option}`);
    return enumName ? translate(`enums.${enumName}.${option}`) : translate(`enums.${option}`);
  };

  const createEndAdornment = (fieldConfig: any): ReactNode => (
    <InputAdornment position="end">
      <IconButton aria-label="clear field" onClick={() => fieldConfig.setter('')} size="small">
        <ClearIcon sx={{ color: theme.palette.grey[500], padding: '4px', margin: '4px' }} />
      </IconButton>
    </InputAdornment>
  );

  return (
    <Stack spacing={2} width="100%" alignItems="center" direction={{ xs: 'column', sm: 'row' }}>
      {searchFields &&
        searchFields.map((fieldConfig: any, index: number) => {
          if (fieldConfig.type === SearchFieldType.TextField) {
            return (
              <TextField
                key={index}
                label={fieldConfig.label}
                value={fieldConfig.value}
                size="small"
                sx={{ width: { xs: '100%', md: '300px' } }}
                onChange={(e) => fieldConfig.setter(e.target.value)}
                InputProps={{ endAdornment: fieldConfig.value && createEndAdornment(fieldConfig) }}
              />
            );
          }
          if (fieldConfig.type === SearchFieldType.SelectField) {
            return (
              <TextField
                select
                key={index}
                label={fieldConfig.label}
                value={fieldConfig.value}
                size="small"
                sx={{ width: { xs: '100%', md: '300px' } }}
                onChange={(e) => fieldConfig.setter(e.target.value)}
                InputProps={{ endAdornment: fieldConfig.value && createEndAdornment(fieldConfig) }}
              >
                {Object.values(fieldConfig.options).map((option: any) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {getOptionLabel(fieldConfig.enum, option)}
                    </MenuItem>
                  );
                })}
              </TextField>
            );
          }
          if (fieldConfig.type === SearchFieldType.DateRange) {
            return (
              <Stack key={index} direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <DatePicker
                  inputFormat="yyyy/MM/DD"
                  mask="____/__/__"
                  value={fieldConfig.startDate}
                  maxDate={moment(new Date())}
                  openTo="day"
                  onChange={(date) => fieldConfig.setStartDate(date)}
                  views={['year', 'month', 'day']}
                  label={translate('form.startDate')}
                  renderInput={(params) => (
                    <TextField {...params} size="small" sx={{ flex: 1 }} error={false} helperText={''} />
                  )}
                />
                <DatePicker
                  inputFormat="yyyy/MM/DD"
                  mask="____/__/__"
                  value={fieldConfig.endDate}
                  onChange={(date) => fieldConfig.setEndDate(date)}
                  openTo="day"
                  views={['year', 'month', 'day']}
                  label={translate('form.endDate')}
                  renderInput={(params) => (
                    <TextField {...params} size="small" sx={{ flex: 1 }} error={false} helperText={''} />
                  )}
                />
              </Stack>
            );
          }
        })}
    </Stack>
  );
};

export default ListingPageSearchInputs;
