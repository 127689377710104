import styled from '@mui/material/styles/styled';
import { DataGrid } from '@mui/x-data-grid/DataGrid/DataGrid';
import { DataGridProps } from '@mui/x-data-grid/models/props/DataGridProps';

export const HeaderAndRowHeightAdjustedDataGrid = styled(DataGrid)<DataGridProps>(() => ({
  '& .MuiDataGrid-columnHeaderTitle': {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    lineHeight: 'initial',
  },
  '& .MuiDataGrid-renderingZone': {
    maxHeight: 'none !important',
  },
  '& .MuiDataGrid-cell': {
    lineHeight: 'unset !important',
    maxHeight: 'none !important',
    whiteSpace: 'normal',
  },
  '& .MuiDataGrid-row': {
    maxHeight: 'none !important',
  },
}));
