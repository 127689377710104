import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { ReactNode } from 'react';
import Breadcrumbs, { Props as BreadcrumbsProps } from './Breadcrumbs';

interface Props extends BreadcrumbsProps {
  action?: ReactNode;
  heading: string;
  moreLink?: string;
}

export default function HeaderBreadcrumbs({ links, action, heading, moreLink = '', sx, ...other }: Props) {
  return (
    <Box sx={{ mb: 5, ...sx }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            {heading}
          </Typography>
          <Breadcrumbs links={links} {...other} />
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>

      <Box sx={{ mt: 2 }}>
        <Link href={moreLink} target="_blank" rel="noopener" variant="body2">
          {moreLink}
        </Link>
      </Box>
    </Box>
  );
}
