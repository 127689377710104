import { format, getTime, formatDistanceToNow } from 'date-fns';
import moment from 'moment';
import { CalendarView } from 'enums/CalendarView';

export function fMomentDate(date: Date | string) {
  if (!date) return null;
  return moment(date).format('YYYY-MM-DD HH:mm');
}

export function fMomentHourMinute(date: Date | string) {
  if (!date) return null;
  return moment(date).format('HH:mm');
}

export function fDate(date: Date | string | number) {
  if (!date) return null;
  return format(new Date(date), 'yyyy-MM-dd');
}

export function fDateTime(date: Date | string | number) {
  if (!date) return null;
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date: Date | string | number) {
  if (!date) return null;
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  if (!date) return null;
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: Date | string | number) {
  if (!date) return null;
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function calculateStartDate(view: CalendarView, date: Date): string | null {
  switch (view) {
    case CalendarView.DAY_VIEW: {
      return moment(date).startOf('day').utc().toISOString();
    }
    case CalendarView.WEEK_MOBILE_VIEW: {
      return moment(date).startOf('isoWeek').utc().toISOString();
    }
    case CalendarView.WEEK_VIEW: {
      return moment(date).startOf('isoWeek').utc().toISOString();
    }
    case CalendarView.MONTH_VIEW: {
      return moment(date).startOf('month').utc().toISOString();
    }
    default: {
      return null;
    }
  }
}

export function calculateEndDate(view: CalendarView, date: Date): string | null {
  switch (view) {
    case CalendarView.DAY_VIEW: {
      return moment(date).endOf('day').utc().toISOString();
    }
    case CalendarView.WEEK_MOBILE_VIEW:
    case CalendarView.WEEK_VIEW: {
      const weekEndDate = moment(date).startOf('isoWeek').utc().toISOString();
      return moment(weekEndDate).add(7, 'days').toDate().toISOString();
    }
    case CalendarView.MONTH_VIEW: {
      return moment(date).endOf('month').utc().toISOString();
    }
    default: {
      return null;
    }
  }
}
