import config from 'config';
import NotificationProvider from 'contexts/NotificationContext';
import { UserRole } from 'generated/globalTypes';
import AuthGuard from 'guards/AuthGuard';
import GuestGuard from 'guards/GuestGuard';
import RoleBasedGuard from 'guards/RoleBasedGuard';
import useTitle from 'hooks/useTitle';
import ContactPersonsList from 'pages/master/ContactPersonsList';
import { ElementType, lazy, Suspense, useEffect } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import { AuthProvider } from '../contexts/JWTContext';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

const loadable = (Component: ElementType) => (props: any) => {
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

const managerAdminStartup = [UserRole.MANAGER, UserRole.ADMIN, UserRole.STARTUP_ADMIN, UserRole.SYSTEM_ADMIN];
const extendedAdmins = [
  ...config.managerAdminSys,
  ...[UserRole.COORDINATION_ADMIN, UserRole.FINANCIAL_ADMIN, UserRole.STARTUP_ADMIN],
];
const calendarAccess = [...config.managerAdminSys, ...config.operatorDoctor, UserRole.COORDINATION_ADMIN];

const routesWithTitleMenuItems = ['studies', 'proposal', 'participant-studies'];

export default function Router() {
  const { setMenuItems } = useTitle();
  const { pathname } = useLocation();

  useEffect(() => {
    const pathMatching = routesWithTitleMenuItems.reduce((value, routeWithTitle) => {
      if (pathname.search(routeWithTitle) >= 0) return true;
      return value;
    }, false);

    if (pathMatching) return;

    setMenuItems(() => []);
  }, [pathname, setMenuItems]);

  return useRoutes([
    {
      path: '/login',
      element: (
        <AuthProvider>
          <GuestGuard>
            <LoginPage />
          </GuestGuard>
        </AuthProvider>
      ),
    },
    {
      path: '/password',
      children: [
        { path: 'forgot', element: <ForgotPassword /> },
        { path: 'reset', element: <ResetPassword /> },
      ],
    },
    { path: '/registration', element: <Registration /> },
    {
      path: '/',
      element: (
        <AuthProvider>
          <AuthGuard>
            <NotificationProvider>
              <DashboardLayout />
            </NotificationProvider>
          </AuthGuard>
        </AuthProvider>
      ),
      children: [
        {
          path: '/organizations',
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <OrganizationListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'add',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <OrganizationAddPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':organizationId',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <OrganizationEditPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: '/sponsors',
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <SponsorsListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'add',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <SponsorAddPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':sponsorId',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <SponsorEditPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: '/cros',
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <CrosListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'add',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <CroAddPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':croId',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <CroEditPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: '/imaging-vendors',
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <ImagingVendorsListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'add',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <ImagingVendorAddPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':imagingVendorId',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <ImagingVendorEditPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: '/payers',
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <PayersListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'add',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <PayerAddPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':payerId',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <PayerEditPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: '/services',
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <ServicesListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'add',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <ServiceAddPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':serviceId',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <ServiceEditPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: '/sites',
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <SitesList />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'add',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <SiteAdd />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':siteId',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <SiteEdit />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: '/locations',
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <LocationsList />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'add',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <LocationAdd />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':locationId',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <LocationEdit />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: '/devices',
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <DevicesList />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'add',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <DeviceAdd />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':deviceId',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <DeviceEdit />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: '/commission-recipients',
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <CommissionRecipientList />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'add',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <CommissionRecipientAdd />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <CommissionRecipientEdit />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: '/studies',
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <StudiesList />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':studyId/:tabName',
              element: (
                <RoleBasedGuard permissions={extendedAdmins}>
                  <StudyEdit />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: '/calendar',
          element: (
            <RoleBasedGuard permissions={calendarAccess}>
              <Calendar />
            </RoleBasedGuard>
          ),
        },
        {
          path: '/proposals',
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard permissions={managerAdminStartup}>
                  <ProposalsList />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'add',
              element: (
                <RoleBasedGuard permissions={managerAdminStartup}>
                  <ProposalAdd />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':proposalId/:tabName',
              element: (
                <RoleBasedGuard permissions={managerAdminStartup}>
                  <ProposalEdit />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: '/users',
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard permissions={[UserRole.ADMIN, UserRole.SYSTEM_ADMIN, UserRole.COORDINATION_ADMIN]}>
                  <UserListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'add',
              element: (
                <RoleBasedGuard permissions={[UserRole.ADMIN, UserRole.SYSTEM_ADMIN, UserRole.COORDINATION_ADMIN]}>
                  <UserAdd />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':userId',
              element: (
                <RoleBasedGuard permissions={[UserRole.ADMIN, UserRole.SYSTEM_ADMIN, UserRole.COORDINATION_ADMIN]}>
                  <UserEdit />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: '/contacts',
          element: (
            <RoleBasedGuard permissions={extendedAdmins}>
              <ContactPersonsList />
            </RoleBasedGuard>
          ),
        },
        {
          path: '/proposal-contracts',
          element: (
            <RoleBasedGuard permissions={managerAdminStartup}>
              <ProposalContractsList />
            </RoleBasedGuard>
          ),
        },
        {
          path: '/participant-payoffs',
          element: <ParticipantPayoffsList />,
        },
        { path: '/profile', element: <ProfilePage /> },
        {
          path: '/dashboard',
          element: (
            <RoleBasedGuard permissions={extendedAdmins}>
              <Dashboard />
            </RoleBasedGuard>
          ),
        },
        {
          path: '/reports',
          element: (
            <RoleBasedGuard permissions={config.reportingAdmins}>
              <Reports />
            </RoleBasedGuard>
          ),
        },
        {
          path: '/participant-examinations',
          element: (
            <RoleBasedGuard
              permissions={[
                UserRole.ADMIN,
                UserRole.SYSTEM_ADMIN,
                UserRole.COORDINATION_ADMIN,
                UserRole.MANAGER,
                UserRole.DOCTOR,
                UserRole.OPERATOR,
              ]}
            >
              <ExaminationsList />
            </RoleBasedGuard>
          ),
        },
        {
          path: '/participant-studies',
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard permissions={[UserRole.DOCTOR, UserRole.OPERATOR]}>
                  <StudiesForParticipantsList />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':studyId/:tabName',
              element: (
                <RoleBasedGuard permissions={[UserRole.DOCTOR, UserRole.OPERATOR]}>
                  <StudyForParticipantsEdit />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: '/payoffs',
          element: (
            <RoleBasedGuard
              permissions={[UserRole.SYSTEM_ADMIN, UserRole.ADMIN, UserRole.MANAGER, UserRole.FINANCIAL_ADMIN]}
            >
              <PayoffsList />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Auth
const LoginPage = loadable(lazy(() => import('../pages/auth/Login')));
const ForgotPassword = loadable(lazy(() => import('../pages/auth/PasswordForgot')));
const ResetPassword = loadable(lazy(() => import('../pages/auth/ResetPassword')));
const Registration = loadable(lazy(() => import('../pages/auth/Registration')));

// Master

// Calendar
const Calendar = loadable(lazy(() => import('../pages/master/Calendar')));

// Organization
const OrganizationListPage = loadable(lazy(() => import('../pages/master/OrganizationsList')));
const OrganizationAddPage = loadable(lazy(() => import('../pages/master/OrganizationAdd')));
const OrganizationEditPage = loadable(lazy(() => import('../pages/master/OrganizationEdit')));

// Sponsor
const SponsorsListPage = loadable(lazy(() => import('../pages/master/SponsorsList')));
const SponsorAddPage = loadable(lazy(() => import('../pages/master/SponsorAdd')));
const SponsorEditPage = loadable(lazy(() => import('../pages/master/SponsorEdit')));

// Cro
const CrosListPage = loadable(lazy(() => import('../pages/master/CrosList')));
const CroAddPage = loadable(lazy(() => import('../pages/master/CroAdd')));
const CroEditPage = loadable(lazy(() => import('../pages/master/CroEdit')));

// ImagingVendor
const ImagingVendorsListPage = loadable(lazy(() => import('../pages/master/ImagingVendorsList')));
const ImagingVendorAddPage = loadable(lazy(() => import('../pages/master/ImagingVendorAdd')));
const ImagingVendorEditPage = loadable(lazy(() => import('../pages/master/ImagingVendorEdit')));

// Payer
const PayersListPage = loadable(lazy(() => import('../pages/master/PayersList')));
const PayerAddPage = loadable(lazy(() => import('../pages/master/PayerAdd')));
const PayerEditPage = loadable(lazy(() => import('../pages/master/PayerEdit')));

// Service
const ServicesListPage = loadable(lazy(() => import('../pages/master/ServicesList')));
const ServiceAddPage = loadable(lazy(() => import('../pages/master/ServiceAdd')));
const ServiceEditPage = loadable(lazy(() => import('../pages/master/ServiceEdit')));
// Site
const SitesList = loadable(lazy(() => import('../pages/master/SitesList')));
const SiteAdd = loadable(lazy(() => import('../pages/master/SiteAdd')));
const SiteEdit = loadable(lazy(() => import('../pages/master/SiteEdit')));

// Proposal
const ProposalsList = loadable(lazy(() => import('../pages/master/ProposalsList')));
const ProposalAdd = loadable(lazy(() => import('../pages/master/ProposalAdd')));
const ProposalEdit = loadable(lazy(() => import('../pages/master/ProposalEdit')));

// Location
const LocationsList = loadable(lazy(() => import('../pages/master/LocationsList')));
const LocationAdd = loadable(lazy(() => import('../pages/master/LocationAdd')));
const LocationEdit = loadable(lazy(() => import('../pages/master/LocationEdit')));

// Device
const DevicesList = loadable(lazy(() => import('../pages/master/DevicesList')));
const DeviceAdd = loadable(lazy(() => import('../pages/master/DeviceAdd')));
const DeviceEdit = loadable(lazy(() => import('../pages/master/DeviceEdit')));

// Study
const StudiesList = loadable(lazy(() => import('../pages/master/StudiesList')));
const StudyEdit = loadable(lazy(() => import('../pages/master/StudyEdit')));
const StudiesForParticipantsList = loadable(lazy(() => import('../pages/master/StudiesForParticipantsList')));
const StudyForParticipantsEdit = loadable(lazy(() => import('../pages/master/StudyForParticipantsEdit')));

// User
const UserListPage = loadable(lazy(() => import('../pages/master/UsersList')));
const UserAdd = loadable(lazy(() => import('../pages/master/UserAdd')));
const UserEdit = loadable(lazy(() => import('../pages/master/UserEdit')));

// CommissionRecipient
const CommissionRecipientList = loadable(lazy(() => import('../pages/master/CommissionRecipientList')));
const CommissionRecipientAdd = loadable(lazy(() => import('../pages/master/CommissionRecipientAdd')));
const CommissionRecipientEdit = loadable(lazy(() => import('../pages/master/CommissionRecipientEdit')));

// ProposalContracts
const ProposalContractsList = loadable(lazy(() => import('../pages/master/ProposalContractsList')));

// ExaminationsList
const ExaminationsList = loadable(lazy(() => import('../pages/master/ExaminationsList')));

// ParticipantPayoffs
const ParticipantPayoffsList = loadable(lazy(() => import('../pages/master/ParticipantPayoffsList')));

const PayoffsList = loadable(lazy(() => import('../pages/master/PayoffsList')));

// Profile
const ProfilePage = loadable(lazy(() => import('../pages/master/ProfilePage')));

// Dashboard
const Dashboard = loadable(lazy(() => import('../pages/Dashboard')));

// Reports
const Reports = loadable(lazy(() => import('../pages/master/ReportsList')));

const NotFound = loadable(lazy(() => import('../pages/Page404')));
const Page500 = loadable(lazy(() => import('../pages/Page500')));
