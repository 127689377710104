import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";
import NotificationItem from 'components/notification/NotificationItem';
import { ListNotifications_notifications_items as Notification } from 'generated/ListNotifications';
import useLocales from 'hooks/useLocales';
import { useState } from 'react';
import { IconButtonAnimate } from '../../../components/animate';
import Iconify from '../../../components/Iconify';
import MenuPopover from '../../../components/MenuPopover';

export default function NotificationsPopover({
  notifications,
  total,
}: {
  notifications: Notification[];
  total: number;
}) {
  const { translate } = useLocales();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => setOpen(event.currentTarget);

  const handleClose = () => setOpen(null);

  return (
    <>
      <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={total} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{translate('notifications')}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {translate('unreadMessages', { count: total })}
            </Typography>
          </Box>
        </Box>

        {notifications.length > 0 && (
          <>
            <Divider sx={{ borderStyle: 'dashed' }} />

            <Box sx={{ maxHeight: '80vh', overflow: 'auto' }}>
              <List
                sx={{ maxHeight: '100%' }}
                disablePadding
                subheader={
                  <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                    {translate('new')}
                  </ListSubheader>
                }
              >
                {notifications.map((notification) => (
                  <NotificationItem key={notification.id} notification={notification} handleClose={handleClose} />
                ))}
              </List>
            </Box>
          </>
        )}
      </MenuPopover>
    </>
  );
}
