import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import MyAvatar from 'components/MyAvatar';
import useAuth from 'hooks/useAuth';
import useLocales from 'hooks/useLocales';
import { NavLink as RouterLink } from 'react-router-dom';
import formatFullName from 'utils/formatFullName';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

type Props = {
  isCollapse: boolean | undefined;
};

export default function NavbarAccount({ isCollapse }: Props) {
  const auth = useAuth();
  const { translate } = useLocales();

  return (
    <Link underline="none" sx={{ color: 'text.primary' }} component={RouterLink} to={`/profile`}>
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <MyAvatar />
        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Typography
              variant="subtitle2"
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                ...(!isCollapse && {
                  width: '145px',
                }),
              }}
            >
              {auth?.user?.name &&
                formatFullName({
                  prefix: auth.user.name.prefix,
                  last: auth.user.name.last,
                  first: auth.user.name.first,
                })}
            </Typography>
          </Box>
          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            {translate(`enums.${auth?.user?.role}`)}
          </Typography>
        </Box>
      </RootStyle>
    </Link>
  );
}
