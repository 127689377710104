import { PieChart } from '@mui/icons-material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HomeIcon from '@mui/icons-material/Home';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LayersIcon from '@mui/icons-material/Layers';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import TableChartIcon from '@mui/icons-material/TableChart';
import WorkIcon from '@mui/icons-material/Work';
import config from 'config';
import { UserRole } from 'generated/globalTypes';
import useAuth from 'hooks/useAuth';
import useAuthorization from 'hooks/useAuthorization';
import useLocales from 'hooks/useLocales';
import { NavConfig } from 'types/ctms/Navbar';
import SvgIconStyle from '../../../components/SvgIconStyle';

const getIcon = (name: string) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
};

function GetSidebarConfig(): NavConfig[] {
  const { user } = useAuth();
  const { translate } = useLocales();
  const { canAccessCalendarView } = useAuthorization();

  const MAIN_HEADER = user?.role && config.operatorDoctor.includes(user.role) ? '' : translate('nav.general');
  const MAIN_SUBHEADER = user?.role && config.operatorDoctor.includes(user.role) ? '' : translate('nav.ctms');

  if (!user?.role) return [];

  const navbarMain: NavConfig = { subheader: MAIN_HEADER, items: [] };

  if (config.reportingAdmins.includes(user.role)) {
    navbarMain.items.push(
      { title: translate('mainpage'), path: '/dashboard', icon: <HomeIcon /> },
      { title: translate('reportspage'), path: '/reports', icon: <TableChartIcon /> }
    );
  }

  if (canAccessCalendarView.includes(user.role)) {
    navbarMain.items.push({ title: translate('calendarpage'), path: '/calendar', icon: <CalendarTodayIcon /> });
  }

  const navbarOptions: NavConfig = { subheader: MAIN_SUBHEADER, items: [] };

  if ([...config.managerAdminSys, UserRole.FINANCIAL_ADMIN].includes(user.role)) {
    navbarOptions.items.push({
      title: translate('form.payoffs'),
      path: '/payoffs',
      icon: <PieChart />,
    });
  }

  if ([...config.managerAdminSys, UserRole.STARTUP_ADMIN].includes(user.role)) {
    navbarOptions.items.push({ title: translate('proposals'), path: '/proposals', icon: ICONS.ecommerce });
  }

  if ([...config.managerAdminSys, UserRole.STARTUP_ADMIN].includes(user.role)) {
    navbarOptions.items.push({
      title: translate('proposalContracts'),
      path: '/proposal-contracts',
      icon: <WorkIcon />,
    });
  }

  if ([...config.managerAdminSys, UserRole.FINANCIAL_ADMIN, UserRole.COORDINATION_ADMIN].includes(user?.role)) {
    const mainData = {
      title: 'Törzsadatok',
      path: '#',
      icon: <LayersIcon />,
      children: [
        {
          title: translate('organizations'),
          path: '/organizations',
        },
        {
          title: translate('sites'),
          path: '/sites',
        },
        {
          title: translate('locations'),
          path: '/locations',
        },
        {
          title: translate('sponsors'),
          path: '/sponsors',
        },
        {
          title: translate('cros'),
          path: '/cros',
        },
        {
          title: translate('imagingVendors'),
          path: '/imaging-vendors',
        },
        {
          title: translate('payers'),
          path: '/payers',
        },
        {
          title: translate('devices'),
          path: '/devices',
        },
        {
          title: translate('services'),
          path: '/services',
        },
        {
          title: translate('contactPersons'),
          path: '/contacts',
        },
        {
          title: translate('commissionRecipients'),
          path: '/commission-recipients',
        },
      ],
    };

    if ([UserRole.ADMIN, UserRole.SYSTEM_ADMIN, UserRole.COORDINATION_ADMIN, UserRole.MANAGER].includes(user.role)) {
      navbarMain.items.push({
        title: translate('currentExaminations'),
        path: '/participant-examinations',
        icon: <MonitorHeartIcon />,
      });
    }

    if ([UserRole.ADMIN, UserRole.SYSTEM_ADMIN, UserRole.COORDINATION_ADMIN].includes(user?.role)) {
      mainData.children.unshift({
        title: translate('users'),
        path: '/users',
      });
    }

    navbarOptions.items.push({ title: translate('studies'), path: '/studies', icon: <AssignmentIcon /> }, mainData);
  }

  if ([UserRole.OPERATOR, UserRole.DOCTOR].includes(user.role)) {
    navbarOptions.items.push(
      { title: translate('currentExaminations'), path: '/participant-examinations', icon: <MonitorHeartIcon /> },
      { title: translate('studies2'), path: '/participant-studies', icon: <AssignmentIcon /> },
      { title: translate('participantPayoffs'), path: '/participant-payoffs', icon: ICONS.ecommerce }
    );
  }

  return [navbarMain, navbarOptions];
}

export default GetSidebarConfig;
