import axios from './axios';
import config from 'config/config';
import { UserRole } from 'generated/globalTypes';
import LocalStorageManager from './LocalStorageManager';
import { Observable } from '@apollo/client';

export const isSysAdmin = (role?: UserRole): boolean => {
  return role ? role === UserRole.SYSTEM_ADMIN : false;
};

export const handleLogout = () => {
  LocalStorageManager.removeToken();
  LocalStorageManager.removeRefreshToken();
  LocalStorageManager.removeUser();
  location.href = '/login';
};

export const handleAuthenticationError = async () => {
  const refreshToken = LocalStorageManager.getRefreshToken();

  try {
    const { data } = await axios.post(config.refreshEndpoint, { refreshToken });
    LocalStorageManager.setToken(data.token);
  } catch (err) {
    handleLogout();
  }
};

export const promiseToObservable = (promise: Promise<any>) => {
  return new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      (err) => subscriber.error(err)
    );
  });
};
