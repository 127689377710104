/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Type of a contact person
 */
export enum ContactPersonType {
  BUDGET = "BUDGET",
  CONTRACT = "CONTRACT",
  COORDINATOR = "COORDINATOR",
  CRA = "CRA",
  FINANCE = "FINANCE",
  MONITOR = "MONITOR",
  OTHER = "OTHER",
  PRINCIPAL_INVESTIGATOR = "PRINCIPAL_INVESTIGATOR",
}

/**
 * Contract status of the Proposal contracts
 */
export enum ContractStatus {
  ACCEPTED = "ACCEPTED",
  COMMENTED = "COMMENTED",
  DRAFT = "DRAFT",
  PREACCEPTED = "PREACCEPTED",
}

export enum Currency {
  EUR = "EUR",
  HUF = "HUF",
  USD = "USD",
}

export enum DistributionFeeType {
  LOCATION = "LOCATION",
  ORGANIZATION = "ORGANIZATION",
  PARTICIPANT = "PARTICIPANT",
}

/**
 * Type of finiancial distribution template
 */
export enum DistributionTemplateType {
  IMAGING_AND_ANALYSIS = "IMAGING_AND_ANALYSIS",
  SERVICE_ONLY = "SERVICE_ONLY",
}

/**
 * Distribution type of a service
 */
export enum DistributionType {
  IMAGING_AND_ANALYSIS = "IMAGING_AND_ANALYSIS",
  SERVICE_ONLY = "SERVICE_ONLY",
  SERVICE_SETUP = "SERVICE_SETUP",
  START_UP = "START_UP",
}

/**
 * Type of an uploaded document
 */
export enum DocumentType {
  COMPLETION_CERTIFICATE = "COMPLETION_CERTIFICATE",
  CONFIDENTALLY_AGREEMENT = "CONFIDENTALLY_AGREEMENT",
  CONTRACT = "CONTRACT",
  COUNTER_PROPOSAL = "COUNTER_PROPOSAL",
  CV = "CV",
  DELEGATION_LOG = "DELEGATION_LOG",
  FRAMEWORK_AGREEMENT = "FRAMEWORK_AGREEMENT",
  GCP = "GCP",
  IMAGING_MANUAL = "IMAGING_MANUAL",
  INDIVIDUAL_CONTRACT_DOCUMENT = "INDIVIDUAL_CONTRACT_DOCUMENT",
  INVOICE = "INVOICE",
  MAINTENANCE = "MAINTENANCE",
  NDA = "NDA",
  ORDER = "ORDER",
  OTHER = "OTHER",
  PRIVACY_STATEMENT = "PRIVACY_STATEMENT",
  PROTOCOL_DOCUMENT = "PROTOCOL_DOCUMENT",
  SCANNER_INFORMATION = "SCANNER_INFORMATION",
  TRAINING_CERTIFICATE = "TRAINING_CERTIFICATE",
  USER_CONTRACT = "USER_CONTRACT",
}

/**
 * The status of the examinations
 */
export enum ExaminationStatus {
  DELETED = "DELETED",
  PAID = "PAID",
  READY_FOR_PAYOFF = "READY_FOR_PAYOFF",
  SCHEDULED = "SCHEDULED",
  WAITING_FOR_REFERRAL = "WAITING_FOR_REFERRAL",
  WAITING_FOR_RESCHEDULE_DATE = "WAITING_FOR_RESCHEDULE_DATE",
  WAITING_FOR_SCHEDULE_DATE = "WAITING_FOR_SCHEDULE_DATE",
  WAITING_ON_PARTICIPANT_INVOICES = "WAITING_ON_PARTICIPANT_INVOICES",
}

/**
 * Type of an invoice
 */
export enum InvoiceType {
  INCOMING = "INCOMING",
  OUTGOING = "OUTGOING",
}

/**
 * Type of an Notification
 */
export enum NotificationType {
  NOTIFY_ADMINS_ABOUT_END_OF_SETTLEMENT_PERIOD = "NOTIFY_ADMINS_ABOUT_END_OF_SETTLEMENT_PERIOD",
  NOTIFY_ADMINS_AND_MANAGERS_ABOUT_UNPAID_INVOICES = "NOTIFY_ADMINS_AND_MANAGERS_ABOUT_UNPAID_INVOICES",
  NOTIFY_DOCTORS_IMAGING_READY = "NOTIFY_DOCTORS_IMAGING_READY",
  NOTIFY_DOCTORS_IMAGING_READY_DEADLINE = "NOTIFY_DOCTORS_IMAGING_READY_DEADLINE",
  NOTIFY_DOCTORS_IMAGING_READY_WITH_RECIST = "NOTIFY_DOCTORS_IMAGING_READY_WITH_RECIST",
  NOTIFY_OPERATOR_AND_COORDINATION_ADMIN_TO_UPLOAD_IMAGE = "NOTIFY_OPERATOR_AND_COORDINATION_ADMIN_TO_UPLOAD_IMAGE",
  NOTIFY_OPERATOR_EXAMINATION_CREATED = "NOTIFY_OPERATOR_EXAMINATION_CREATED",
  NOTIFY_PARTICIPANTS_SCHEDULED_EXAMINATION = "NOTIFY_PARTICIPANTS_SCHEDULED_EXAMINATION",
}

/**
 * Order of the sort
 */
export enum OrderType {
  ASC = "ASC",
  DESC = "DESC",
}

/**
 * Type of the organization
 */
export enum OrganizationType {
  IMAGING = "IMAGING",
  PROVIDER = "PROVIDER",
}

/**
 * Patient status of the study examinations
 */
export enum PatientStatus {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  SUSPENDED = "SUSPENDED",
}

/**
 * Status of a payoff
 */
export enum PayoffStatus {
  CLOSED = "CLOSED",
  COMPLETION_CERTIFICATE_UPLOADED = "COMPLETION_CERTIFICATE_UPLOADED",
  DRAFT = "DRAFT",
  INVOICE_SENT = "INVOICE_SENT",
  WAITING_ON_PARTICIPANT_INVOICES = "WAITING_ON_PARTICIPANT_INVOICES",
}

/**
 * Status of a proposal
 */
export enum ProposalStatus {
  ACCEPTED = "ACCEPTED",
  DENIED = "DENIED",
  DRAFT = "DRAFT",
  MODIFICATION_NEEDED = "MODIFICATION_NEEDED",
  SENT = "SENT",
}

export enum SearchRelation {
  CONTAINS = "CONTAINS",
  EQUALS = "EQUALS",
}

export enum StudySearchField {
  CRO_NAME = "CRO_NAME",
  DEVICE_NAME = "DEVICE_NAME",
  DOCTORS_NAME = "DOCTORS_NAME",
  IMAGING_VENDOR_NAME = "IMAGING_VENDOR_NAME",
  LOCATION_NAME = "LOCATION_NAME",
  OPERATORS_NAME = "OPERATORS_NAME",
  ORGANIZATION_NAME = "ORGANIZATION_NAME",
  PATIENT_NAME = "PATIENT_NAME",
  PATIENT_RANDOM_NUMBER = "PATIENT_RANDOM_NUMBER",
  PATIENT_SCREENING_NUMBER = "PATIENT_SCREENING_NUMBER",
  PAYER_NAME = "PAYER_NAME",
  PROTOCOL_NUMBER = "PROTOCOL_NUMBER",
  SERVICE_NAME = "SERVICE_NAME",
  SITE_NAME = "SITE_NAME",
  SPONSOR_NAME = "SPONSOR_NAME",
  STUDY_STATUS = "STUDY_STATUS",
}

/**
 * Contract status of the Proposal contracts
 */
export enum StudyStatus {
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
  CONTRACT = "CONTRACT",
  DIAGNOSTIC_OVER = "DIAGNOSTIC_OVER",
  FILL_UP = "FILL_UP",
  INACTIVE = "INACTIVE",
  INIT = "INIT",
}

/**
 * Contract type between a User and an Organization
 */
export enum UserContractType {
  CONTRACTOR = "CONTRACTOR",
  EMPLOYEE = "EMPLOYEE",
}

/**
 * Role of a user
 */
export enum UserRole {
  ADMIN = "ADMIN",
  COORDINATION_ADMIN = "COORDINATION_ADMIN",
  DOCTOR = "DOCTOR",
  FINANCIAL_ADMIN = "FINANCIAL_ADMIN",
  MANAGER = "MANAGER",
  OPERATOR = "OPERATOR",
  STARTUP_ADMIN = "STARTUP_ADMIN",
  SYSTEM_ADMIN = "SYSTEM_ADMIN",
}

/**
 * Status of a user
 */
export enum UserStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  SUSPENDED = "SUSPENDED",
}

/**
 * Address input model
 */
export interface AddressInput {
  zipcode?: string | null;
  town?: string | null;
  street?: string | null;
  number?: string | null;
}

export interface CROFilter {
  name?: string | null;
  vatNumber?: string | null;
}

export interface CROListingArg {
  order?: CROOrder | null;
  filter?: CROFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface CROOrder {
  name?: OrderType | null;
}

export interface CertificateDocumentInput {
  upload: any;
  type: DocumentType;
  completionCertificateId: string;
}

export interface CommissionRecipientFilter {
  name?: string | null;
}

export interface CommissionRecipientListArgs {
  order?: CommissionRecipientOrder | null;
  filter?: CommissionRecipientFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface CommissionRecipientOrder {
  name?: OrderType | null;
}

export interface ContactPersonFilter {
  personalEmail?: string | null;
  email?: string | null;
  name?: string | null;
  contactFor?: string | null;
  contactForId?: string | null;
  type?: ContactPersonType | null;
}

export interface ContactPersonListingArg {
  order?: ContactPersonOrder | null;
  filter?: ContactPersonFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface ContactPersonOrder {
  name?: OrderType | null;
}

export interface CoordinationReportCROListingArg {
  order?: CoordinationReportOrder | null;
  filter?: CoordinationReportNameFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface CoordinationReportDeviceFilter {
  device?: string | null;
}

export interface CoordinationReportDeviceListingArg {
  order?: CoordinationReportOrder | null;
  filter?: CoordinationReportDeviceFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface CoordinationReportDoctorOrOperatorListingArg {
  order?: CoordinationReportOrder | null;
  filter?: CoordinationReportNameFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface CoordinationReportImagingVendorListingArg {
  order?: CoordinationReportOrder | null;
  filter?: CoordinationReportNameFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface CoordinationReportLocationListingArg {
  order?: CoordinationReportOrder | null;
  filter?: CoordinationReportNameFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface CoordinationReportNameFilter {
  name?: string | null;
}

export interface CoordinationReportOrder {
  startTime?: OrderType | null;
}

export interface CoordinationReportOrganizationListingArg {
  order?: CoordinationReportOrder | null;
  filter?: CoordinationReportNameFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface CoordinationReportPrincipalInvestigatorListingArg {
  order?: CoordinationReportOrder | null;
  filter?: CoordinationReportNameFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface CoordinationReportSiteListingArg {
  order?: CoordinationReportOrder | null;
  filter?: CoordinationReportNameFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface CoordinationReportSponsorListingArg {
  order?: CoordinationReportOrder | null;
  filter?: CoordinationReportNameFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface CoordinationReportStudyFilter {
  protocolNumber?: string | null;
  studyStatus?: StudyStatus | null;
}

export interface CoordinationReportStudyListingArg {
  order?: CoordinationReportOrder | null;
  filter?: CoordinationReportStudyFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface CoordinationReportStudyLoadFilter {
  protocolNumber?: string | null;
  sponsor?: string | null;
}

export interface CoordinationReportStudyLoadListingArg {
  order?: CoordinationReportOrder | null;
  filter?: CoordinationReportStudyLoadFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface CreateCROInput {
  name: string;
  address: AddressInput;
  vatNumber: string;
  registrationNumber?: string | null;
  euVatNumber?: string | null;
  notes?: string | null;
}

export interface CreateCertificateInput {
  totalFeeNet: number;
  currency: Currency;
  totalFeeSuperGross: number;
  paidAt?: any | null;
}

export interface CreateCommissionRecipientInput {
  name: string;
  address: AddressInput;
  vatNumber: string;
  bankAccount: string;
}

export interface CreateContactPersonInput {
  personalEmail?: string | null;
  personalPhone?: string | null;
  email: string;
  phone: string;
  name: PersonNameInput;
  type: ContactPersonType;
  siteId?: string | null;
  sponsorId?: string | null;
  croId?: string | null;
  payerId?: string | null;
  imagingVendorId?: string | null;
  notes?: string | null;
}

export interface CreateDeviceInput {
  name: string;
  locationId: string;
  organizationId: string;
  manufacturer: string;
  serialNumber: string;
  modelType: string;
  maintenancePeriodDays: number;
  maintenanceContact?: string | null;
  availability?: WeeklyTimePeriodsInput | null;
  softwareInformation?: string | null;
  serviceIds?: (string | null)[] | null;
  notes?: string | null;
}

export interface CreateDeviceSlotInput {
  startTime: any;
  endTime: any;
  deviceId: string;
  examinationId: string;
}

export interface CreateExaminationInput {
  referralDate?: any | null;
  scheduleTime?: any | null;
  deviceSetupReady?: any | null;
  imagingReady?: any | null;
  imagingUploaded?: any | null;
  analysisReady?: any | null;
  analysisValidationReady?: any | null;
  recistAnalysisReady?: any | null;
  externalExamination?: boolean | null;
  studyId: string;
  patientId: string;
  proposalServiceId: string;
  visitTypeId: string;
  deviceId: string;
  participants: ExaminationParticipantInput[];
  notesDoctor?: string | null;
  notesOperator?: string | null;
  notesAdmin?: string | null;
}

export interface CreateImagingVendorInput {
  name: string;
  address: AddressInput;
  notes?: string | null;
}

export interface CreateInvoiceInput {
  type: InvoiceType;
  currency: Currency;
  invoiceNumber: string;
  amountGross: number;
  amountNet: number;
  dueDate: any;
  completionDate: any;
  paidAt?: any | null;
}

export interface CreateLocationInput {
  name: string;
  address: AddressInput;
  organizationId: string;
  notes?: string | null;
  commissionRecipientId: string;
  operatorPaymentNote?: string | null;
}

export interface CreateOrganizationInput {
  name: string;
  address: AddressInput;
  vatNumber: string;
  bankAccount: string;
  type?: OrganizationType | null;
  notes?: string | null;
  companyRegistryNumber?: string | null;
  representative?: string | null;
}

export interface CreatePatientInput {
  name: PersonNameInput;
  socialSecurityNumber: string;
  screeningNumber: string;
  randomNumber?: string | null;
  birthDate?: any | null;
  email?: string | null;
  phone?: string | null;
  studyId: string;
  proposalSiteId: string;
  notes?: string | null;
  status: PatientStatus;
}

export interface CreatePayerInput {
  name: string;
  address: AddressInput;
  vatNumber: string;
  registrationNumber?: string | null;
  euVatNumber?: string | null;
  notes?: string | null;
}

export interface CreatePayoffInput {
  studyId: string;
}

export interface CreatePayoffItemInput {
  payoffId: string;
  examinationId?: string | null;
  proposalServiceId?: string | null;
}

export interface CreateProposalInput {
  protocolNumber: string;
  notes?: string | null;
  currency: Currency;
  imagingProviderId: string;
  serviceProviderId: string;
  sponsorId: string;
  croId?: string | null;
  imagingVendorId?: string | null;
  contactPersonId?: string | null;
}

export interface CreateProposalServiceInput {
  proposalId: string;
  serviceId: string;
  index: number;
  totalCost: number;
  isRecist?: boolean | null;
  visitTypeIds?: string[] | null;
  financialDistribution: any;
}

export interface CreateProposalSiteInput {
  proposalId: string;
  siteNumber?: string | null;
  patientNumber?: number | null;
  siteId: string;
  principalInvestigatorId: string;
  coordinatorId: string;
}

export interface CreateServiceInput {
  name: string;
  organizationId: string;
  distributionType: DistributionType;
  listPrice: number;
  screeningTime?: number | null;
  serviceTime?: number | null;
  doctorIds?: string[] | null;
  operatorIds?: string[] | null;
  deviceIds?: string[] | null;
  notes?: string | null;
  serviceRoles: string[];
}

export interface CreateSiteInput {
  name: string;
  address: AddressInput;
  organizationId: string;
  vatNumber: string;
  bankAccount: string;
  notes?: string | null;
}

export interface CreateSponsorInput {
  name: string;
  address: AddressInput;
  registrationNumber?: string | null;
  vatNumber: string;
  euVatNumber?: string | null;
  notes?: string | null;
}

export interface CreateStudyDeviceInput {
  studyId: string;
  deviceId: string;
  maintenanceDocumentId?: string | null;
}

export interface CreateStudyParticipantInput {
  studyId: string;
  userId: string;
  userContractId?: string | null;
  userContractActivationDate?: any | null;
  cvId?: string | null;
  gcpId?: string | null;
}

export interface CreateUserContractInput {
  name: string;
  contractType: UserContractType;
  organizationId?: string | null;
  userId: string;
  validUntil: any;
  address: AddressInput;
  vatNumber: string;
  bankAccount: string;
}

export interface CreateUserInput {
  role: UserRole;
  personalEmail?: string | null;
  personalPhone?: string | null;
  email: string;
  phone: string;
  name: PersonNameInput;
  organizationId: string;
  gcpSigned?: any | null;
  notes?: string | null;
  serviceIds?: string[] | null;
  locationId?: string | null;
}

export interface CreateVisitTypeInput {
  name: string;
  days: number;
  default?: boolean | null;
}

export interface DeviceFilter {
  name?: string | null;
  locationFor?: string | null;
}

export interface DeviceListingArg {
  order?: DeviceOrder | null;
  filter?: DeviceFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface DeviceMaintenanceFilter {
  deviceId: string;
}

export interface DeviceMaintenanceInput {
  upload: any;
  deviceId: string;
  maintenanceDate: any;
}

export interface DeviceMaintenanceOrder {
  date?: OrderType | null;
}

export interface DeviceMaintenancesListingArg {
  order?: DeviceMaintenanceOrder | null;
  filter?: DeviceMaintenanceFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface DeviceOrder {
  name?: OrderType | null;
}

export interface DeviceSlotFilter {
  deviceId?: string | null;
  examinationId?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  userId?: string | null;
}

export interface DeviceSlotListingArg {
  order?: DeviceSlotOrder | null;
  filter?: DeviceSlotFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface DeviceSlotOrder {
  startTime?: OrderType | null;
}

export interface ExaminationFilter {
  statuses?: ExaminationStatus[] | null;
  serviceName?: string | null;
  userId?: string | null;
  isInPayoff?: boolean | null;
  studyId?: string | null;
  protocolNumber?: string | null;
  patientName?: string | null;
  socialSecurityNumber?: string | null;
  isAnalysisReady?: boolean | null;
  isAnalysisValidationReady?: boolean | null;
}

export interface ExaminationListingArg {
  order?: ExaminationOrder | null;
  filter?: ExaminationFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface ExaminationOrder {
  created?: OrderType | null;
  scheduleTime?: OrderType | null;
}

export interface ExaminationParticipantInput {
  participantId: string;
  participantRole: string;
}

export interface FinancialPeriodicReportOrder {
  startTime?: OrderType | null;
}

export interface FinancialPeriodicReportParticipantFilter {
  name?: string | null;
  startDate?: any | null;
  endDate?: any | null;
}

export interface FinancialPeriodicReportParticipantsListingArg {
  order?: FinancialPeriodicReportOrder | null;
  filter?: FinancialPeriodicReportParticipantFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface FinancialReportHeadOfDepartmentFilter {
  name?: string | null;
}

export interface FinancialReportHeadOfDepartmentListingArg {
  order?: FinancialReportOrder | null;
  filter?: FinancialReportHeadOfDepartmentFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface FinancialReportOrder {
  startTime?: OrderType | null;
}

export interface FinancialReportParticipantFilter {
  name?: string | null;
  role: UserRole;
}

export interface FinancialReportParticipantsListingArg {
  order?: FinancialReportOrder | null;
  filter?: FinancialReportParticipantFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface FinancialReportServiceFilter {
  service?: string | null;
}

export interface FinancialReportServiceListingArg {
  order?: FinancialReportOrder | null;
  filter?: FinancialReportServiceFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface FinancialReportSiteFilter {
  siteName?: string | null;
}

export interface FinancialReportSiteListingArg {
  order?: FinancialReportOrder | null;
  filter?: FinancialReportSiteFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface FinancialReportSponsorListingArg {
  order?: FinancialReportOrder | null;
  filter?: FinancialReportStudyRelatedFieldFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface FinancialReportStudyRelatedFieldFilter {
  sponsor?: string | null;
  cro?: string | null;
  imagingProvider?: string | null;
  serviceProvider?: string | null;
}

export interface FinancialReportTechnicalParticipantFilter {
  name?: string | null;
}

export interface FinancialReportTechnicalParticipantsListingArg {
  order?: FinancialReportOrder | null;
  filter?: FinancialReportTechnicalParticipantFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface GeneralSearchListingArg {
  order?: StudyOrder | null;
  filter?: SearchBodyInput | null;
  take?: number | null;
  page?: number | null;
}

export interface ImagingVendorFilter {
  name?: string | null;
}

export interface ImagingVendorListingArg {
  order?: ImagingVendorOrder | null;
  filter?: ImagingVendorFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface ImagingVendorOrder {
  name?: OrderType | null;
}

export interface InvoiceDocumentInput {
  upload: any;
  type: DocumentType;
  invoiceId: string;
}

export interface LocationFilter {
  name?: string | null;
  organizationName?: string | null;
}

export interface LocationListingArg {
  order?: LocationOrder | null;
  filter?: LocationFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface LocationOrder {
  name?: OrderType | null;
}

export interface OrganizationFilter {
  name?: string | null;
  vatNumber?: string | null;
}

export interface OrganizationListingArg {
  order?: OrganizationOrder | null;
  filter?: OrganizationFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface OrganizationOrder {
  name?: OrderType | null;
}

export interface ParticipantPayoffFilter {
  payoffId?: string | null;
  userId?: string | null;
  protocolNumber?: string | null;
  hasDocument?: boolean | null;
  isPaid?: boolean | null;
}

export interface ParticipantPayoffListingArg {
  order?: ParticipantPayoffOrder | null;
  filter?: ParticipantPayoffFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface ParticipantPayoffOrder {
  created?: OrderType | null;
}

export interface PatientFilter {
  studyId: string;
  name?: string | null;
  socialSecurityNumber?: string | null;
  email?: string | null;
  status?: PatientStatus | null;
}

export interface PatientListingArg {
  order?: PatientOrder | null;
  filter?: PatientFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface PatientOrder {
  name?: OrderType | null;
}

export interface PayerFilter {
  name?: string | null;
  vatNumber?: string | null;
}

export interface PayerListingArg {
  order?: PayerOrder | null;
  filter?: PayerFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface PayerOrder {
  name?: OrderType | null;
}

export interface PayoffDocumentInput {
  upload: any;
  type: DocumentType;
  payoffId: string;
}

export interface PayoffFilter {
  studyId?: string | null;
  status?: PayoffStatus | null;
  sponsorName?: string | null;
  protocol?: string | null;
  startDate?: any | null;
  endDate?: any | null;
}

export interface PayoffItemFilter {
  payoffId?: string | null;
  examinationId?: string | null;
  proposalServiceId?: string | null;
}

export interface PayoffItemListingArg {
  order?: PayoffItemOrder | null;
  filter?: PayoffItemFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface PayoffItemOrder {
  created?: OrderType | null;
  examinationDateOrder?: OrderType | null;
}

export interface PayoffListingArg {
  order?: PayoffOrder | null;
  filter?: PayoffFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface PayoffOrder {
  created?: OrderType | null;
}

export interface PersonNameInput {
  prefix?: string | null;
  first: string;
  last: string;
}

export interface ProposalContractFilter {
  protocolNumber?: string | null;
  sponsorName?: string | null;
  status?: ContractStatus | null;
}

export interface ProposalContractListingArg {
  order?: ProposalContractOrder | null;
  filter?: ProposalContractFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface ProposalContractOrder {
  created?: OrderType | null;
}

export interface ProposalContractUploadInput {
  upload: any;
  proposalContractId: string;
}

export interface ProposalDeleteDocumentInput {
  documentId: string;
  proposalId: string;
}

export interface ProposalDocumentInput {
  upload: any;
  type: DocumentType;
  proposalId: string;
}

export interface ProposalFilter {
  protocolNumber?: string | null;
  sponsorName?: string | null;
  status?: ProposalStatus | null;
}

export interface ProposalListingArg {
  order?: ProposalOrder | null;
  filter?: ProposalFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface ProposalOrder {
  created?: OrderType | null;
}

export interface ProposalServiceFilter {
  proposalId?: string | null;
  serviceTypes?: DistributionType[] | null;
}

export interface ProposalServiceListingArg {
  order?: ProposalServiceOrder | null;
  filter?: ProposalServiceFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface ProposalServiceOrder {
  serviceName?: OrderType | null;
}

export interface ProposalServiceOrderInput {
  id: string;
  index: number;
}

export interface ProposalSiteFilter {
  studyId?: string | null;
  proposalId?: string | null;
  hasAcceptedContract?: boolean | null;
  isActivated?: boolean | null;
}

export interface ProposalSiteListingArg {
  order?: ProposalSiteOrder | null;
  filter?: ProposalSiteFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface ProposalSiteOrder {
  created?: OrderType | null;
}

export interface RequiredDocsInput {
  participantRole: string;
  cvRequired?: boolean | null;
  contractRequired?: boolean | null;
  trainingCertificateRequired?: boolean | null;
  privacyStatementRequired?: boolean | null;
  delegationLogRequired?: boolean | null;
  gcpRequired?: boolean | null;
}

export interface SearchBody {
  field: StudySearchField;
  value: string;
  relation: SearchRelation;
}

export interface SearchBodyInput {
  filters: SearchBody[];
}

export interface ServiceFilter {
  name?: string | null;
  locationName?: string | null;
  organizationName?: string | null;
  distributionTypes?: DistributionType[] | null;
}

export interface ServiceListingArg {
  order?: ServiceOrder | null;
  filter?: ServiceFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface ServiceOrder {
  name?: OrderType | null;
}

export interface SiteFilter {
  name?: string | null;
  vatNumber?: string | null;
}

export interface SiteListingArg {
  order?: SiteOrder | null;
  filter?: SiteFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface SiteOrder {
  name?: OrderType | null;
}

export interface SponsorFilter {
  name?: string | null;
  vatNumber?: string | null;
}

export interface SponsorListingArg {
  order?: SponsorOrder | null;
  filter?: SponsorFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface SponsorOrder {
  name?: OrderType | null;
}

export interface StudyDeviceFilter {
  studyId?: string | null;
  serviceId?: string | null;
}

export interface StudyDeviceListingArg {
  order?: StudyDeviceOrder | null;
  filter?: StudyDeviceFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface StudyDeviceOrder {
  created?: OrderType | null;
}

export interface StudyDocumentInput {
  studyId: string;
  upload: any;
  type: DocumentType;
}

export interface StudyFilter {
  protocolNumber?: string | null;
  sponsorName?: string | null;
  status?: StudyStatus | null;
}

export interface StudyListingArg {
  order?: StudyOrder | null;
  filter?: StudyFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface StudyOrder {
  created?: OrderType | null;
}

export interface StudyParticipantDocumentInput {
  upload: any;
  documentType: DocumentType;
  studyParticipantId: string;
}

export interface StudyParticipantFilter {
  participantName?: string | null;
  studyId?: string | null;
  serviceId?: string | null;
  role?: UserRole | null;
}

export interface StudyParticipantListingArg {
  order?: StudyParticipantOrder | null;
  filter?: StudyParticipantFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface StudyParticipantOrder {
  created?: OrderType | null;
}

export interface StudyServiceFilter {
  studyId?: string | null;
  distributionTypes?: DistributionType[] | null;
}

export interface TimeRangeInput {
  start?: any | null;
  end?: any | null;
}

export interface TrainingUrlInput {
  participantRole: string;
  url: string;
}

export interface UpdateCROInput {
  name: string;
  address: AddressInput;
  vatNumber: string;
  registrationNumber?: string | null;
  euVatNumber?: string | null;
  notes?: string | null;
  id: string;
}

export interface UpdateCertificateInput {
  totalFeeNet: number;
  currency: Currency;
  totalFeeSuperGross: number;
  paidAt?: any | null;
  id: string;
}

export interface UpdateCommissionRecipientInput {
  name: string;
  address: AddressInput;
  vatNumber: string;
  bankAccount: string;
  id: string;
}

export interface UpdateContactPersonInput {
  personalEmail?: string | null;
  personalPhone?: string | null;
  email: string;
  phone: string;
  name: PersonNameInput;
  type: ContactPersonType;
  siteId?: string | null;
  sponsorId?: string | null;
  croId?: string | null;
  payerId?: string | null;
  imagingVendorId?: string | null;
  notes?: string | null;
  id: string;
}

export interface UpdateDeviceInput {
  name: string;
  locationId: string;
  organizationId: string;
  manufacturer: string;
  serialNumber: string;
  modelType: string;
  maintenancePeriodDays: number;
  maintenanceContact?: string | null;
  availability?: WeeklyTimePeriodsInput | null;
  softwareInformation?: string | null;
  serviceIds?: (string | null)[] | null;
  notes?: string | null;
  id: string;
}

export interface UpdateDeviceSlotInput {
  id: string;
  startTime: any;
  endTime: any;
}

export interface UpdateExaminationInput {
  referralDate?: any | null;
  scheduleTime?: any | null;
  deviceSetupReady?: any | null;
  imagingReady?: any | null;
  imagingUploaded?: any | null;
  analysisReady?: any | null;
  analysisValidationReady?: any | null;
  recistAnalysisReady?: any | null;
  externalExamination?: boolean | null;
  studyId: string;
  patientId: string;
  proposalServiceId: string;
  visitTypeId: string;
  deviceId: string;
  participants: ExaminationParticipantInput[];
  notesDoctor?: string | null;
  notesOperator?: string | null;
  notesAdmin?: string | null;
  id: string;
  hasMarkedNoShow?: boolean | null;
}

export interface UpdateImagingVendorInput {
  name: string;
  address: AddressInput;
  notes?: string | null;
  id: string;
}

export interface UpdateInvoiceInput {
  type: InvoiceType;
  currency: Currency;
  invoiceNumber: string;
  amountGross: number;
  amountNet: number;
  dueDate: any;
  completionDate: any;
  paidAt?: any | null;
  id: string;
}

export interface UpdateLocationInput {
  name: string;
  address: AddressInput;
  organizationId: string;
  notes?: string | null;
  commissionRecipientId: string;
  operatorPaymentNote?: string | null;
  id: string;
}

export interface UpdateOrganizationInput {
  name: string;
  address: AddressInput;
  vatNumber: string;
  bankAccount: string;
  type?: OrganizationType | null;
  notes?: string | null;
  companyRegistryNumber?: string | null;
  representative?: string | null;
  id: string;
}

export interface UpdateParticipantPayoffInput {
  id: string;
  paidAt?: any | null;
  invoiceId?: string | null;
  completionCertificateId?: string | null;
  costDistributionIsValidatedByTheAdminAt?: any | null;
}

export interface UpdatePatientInput {
  name: PersonNameInput;
  socialSecurityNumber: string;
  screeningNumber: string;
  randomNumber?: string | null;
  birthDate?: any | null;
  email?: string | null;
  phone?: string | null;
  studyId: string;
  proposalSiteId: string;
  notes?: string | null;
  status: PatientStatus;
  id: string;
}

export interface UpdatePayerInput {
  name: string;
  address: AddressInput;
  vatNumber: string;
  registrationNumber?: string | null;
  euVatNumber?: string | null;
  notes?: string | null;
  id: string;
}

export interface UpdatePayoffInput {
  id: string;
  invoiceId?: string | null;
  notes?: string | null;
}

export interface UpdatePayoffItemInput {
  id: string;
  paidAmount?: number | null;
  comment?: string | null;
}

export interface UpdateProposalContractInput {
  notes?: string | null;
  proposalId: string;
  id: string;
  status: ContractStatus;
  acceptedOn?: any | null;
}

export interface UpdateProposalInput {
  protocolNumber: string;
  notes?: string | null;
  currency: Currency;
  imagingProviderId: string;
  serviceProviderId: string;
  sponsorId: string;
  croId?: string | null;
  imagingVendorId?: string | null;
  contactPersonId?: string | null;
  id: string;
}

export interface UpdateProposalServiceInput {
  proposalId: string;
  serviceId: string;
  index: number;
  totalCost: number;
  isRecist?: boolean | null;
  visitTypeIds?: string[] | null;
  financialDistribution: any;
  id: string;
}

export interface UpdateProposalServiceOrderInput {
  orderedServices: ProposalServiceOrderInput[];
}

export interface UpdateProposalSiteInput {
  id: string;
  siteNumber?: string | null;
  patientNumber?: number | null;
  siteId: string;
  principalInvestigatorId: string;
  coordinatorId: string;
  activationDate?: any | null;
}

export interface UpdateProposalStatusInput {
  id: string;
  status: ProposalStatus;
}

export interface UpdateServiceInput {
  name: string;
  organizationId: string;
  distributionType: DistributionType;
  listPrice: number;
  screeningTime?: number | null;
  serviceTime?: number | null;
  doctorIds?: string[] | null;
  operatorIds?: string[] | null;
  deviceIds?: string[] | null;
  notes?: string | null;
  serviceRoles: string[];
  id: string;
}

export interface UpdateSiteInput {
  name: string;
  address: AddressInput;
  organizationId: string;
  vatNumber: string;
  bankAccount: string;
  notes?: string | null;
  id: string;
}

export interface UpdateSponsorInput {
  name: string;
  address: AddressInput;
  registrationNumber?: string | null;
  vatNumber: string;
  euVatNumber?: string | null;
  notes?: string | null;
  id: string;
}

export interface UpdateStudyDeviceInput {
  studyId: string;
  deviceId: string;
  maintenanceDocumentId?: string | null;
  id: string;
}

export interface UpdateStudyInput {
  id: string;
  serviceDeliveryDeadline?: number | null;
  serviceDelivery?: string | null;
  imagingDeliveryNote?: string | null;
  settlementPeriod?: number | null;
  imageUploadDeadline?: number | null;
  payerId?: string | null;
  notes?: string | null;
  invoicingInfo?: string | null;
  title?: string | null;
  trainingUrls?: TrainingUrlInput[] | null;
  requiredDocs?: RequiredDocsInput[] | null;
  phantomImagingRequired?: boolean | null;
}

export interface UpdateStudyParticipantInput {
  studyId: string;
  userId: string;
  userContractId?: string | null;
  userContractActivationDate?: any | null;
  cvId?: string | null;
  gcpId?: string | null;
  id: string;
}

export interface UpdateStudyServiceInput {
  id: string;
  defaultDeviceId?: string | null;
  participants: ExaminationParticipantInput[];
  participantCount: any;
}

export interface UpdateStudyStatus {
  id: string;
  status: StudyStatus;
}

export interface UpdateUserContractInput {
  name: string;
  contractType: UserContractType;
  organizationId?: string | null;
  userId: string;
  validUntil: any;
  address: AddressInput;
  vatNumber: string;
  bankAccount: string;
  id: string;
}

export interface UpdateUserInput {
  role: UserRole;
  personalEmail?: string | null;
  personalPhone?: string | null;
  email: string;
  phone: string;
  name: PersonNameInput;
  organizationId: string;
  gcpSigned?: any | null;
  notes?: string | null;
  serviceIds?: string[] | null;
  locationId?: string | null;
  id: string;
}

export interface UpdateUserStatus {
  id: string;
  status: UserStatus;
}

export interface UpdateVisitTypeInput {
  name: string;
  days: number;
  default?: boolean | null;
  id: string;
}

export interface UserContractDocumentInput {
  upload: any;
  documentType: DocumentType;
  userContractId: string;
}

export interface UserDocumentInput {
  upload: any;
  documentType: DocumentType;
  userId: string;
}

export interface UserFilter {
  role?: string | null;
  roles?: UserRole[] | null;
  personalEmail?: string | null;
  email?: string | null;
  name?: string | null;
  organizationName?: string | null;
}

export interface UserListingArg {
  order?: UserOrder | null;
  filter?: UserFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface UserOrder {
  name?: OrderType | null;
}

export interface VisitTypeFilter {
  name?: string | null;
  default?: boolean | null;
}

export interface VisitTypeListingArg {
  order?: VisitTypeOrder | null;
  filter?: VisitTypeFilter | null;
  take?: number | null;
  page?: number | null;
}

export interface VisitTypeOrder {
  name?: OrderType | null;
}

export interface WeeklyTimePeriodsInput {
  monday?: TimeRangeInput[] | null;
  tuesday?: TimeRangeInput[] | null;
  wednesday?: TimeRangeInput[] | null;
  thursday?: TimeRangeInput[] | null;
  friday?: TimeRangeInput[] | null;
  saturday?: TimeRangeInput[] | null;
  sunday?: TimeRangeInput[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
