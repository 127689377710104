type StudyTabNames = 'overview' | 'basedata' | 'services' | 'participants' | 'payoffs' | 'patients' | 'examinations';

const TabNameToIndex: { [key: string]: string } = {
  overview: '1',
  basedata: '2',
  services: '3',
  participants: '4',
  patients: '5',
  examinations: '6',
  payoffs: '7',
};

const TabIndexToTabName: { [key: string]: StudyTabNames } = {
  '1': 'overview',
  '2': 'basedata',
  '3': 'services',
  '4': 'participants',
  '5': 'patients',
  '6': 'examinations',
  '7': 'payoffs',
};

const getTabName = (index: string): StudyTabNames => {
  if (index && index in TabIndexToTabName) return TabIndexToTabName[index];
  return TabIndexToTabName['1'];
};

const getTabIndex = (name?: string): string => {
  if (name && name in TabNameToIndex) return TabNameToIndex[name];
  return TabNameToIndex.overview;
};

const useStudyTabs = () => {
  return {
    getTabIndex,
    getTabName,
  };
};

export default useStudyTabs;
