import { useApolloClient } from '@apollo/client';
import axios from 'axios';
import { Types } from 'enums/AuthEnum';
import { Me } from 'generated/Me';
import { ME_QUERY } from 'gql/User';
import jwt from 'jwt-decode';
import { createContext, useEffect, useReducer } from 'react';
import LocalStorageManager from 'utils/LocalStorageManager';
import { AuthProviderProps, AuthState, JWTActions, JWTContextType, LoginWithPasswordPayload } from 'types/auth';
import config from '../config/';

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  tfaToken: null,
  tfaEnabledForUser: true,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case Types.Initial:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case Types.LoginWithCode:
      return {
        ...state,
        tfaToken: null,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case Types.LoginWithPassword:
      return {
        ...state,
        ...action.payload,
      };
    case Types.Logout:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        tfaToken: null,
      };
    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const client = useApolloClient();

  useEffect(() => {
    const initialize = async () => {
      try {
        const token = LocalStorageManager.getToken();
        let user = LocalStorageManager.getUser();

        if (token) {
          if (!user) {
            const { data } = await client.query<Me>({ query: ME_QUERY });
            user = data.me;
          }

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: initialState,
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: initialState,
        });
      }
    };

    initialize();
  }, [children, client]);

  const loginWithPasswordAndEmail = async (username: string, password: string, remember?: boolean) => {
    let payload: LoginWithPasswordPayload = { username, password };
    if (!config.TWO_FACTOR_AUTH_ENABLED) {
      payload = { ...payload, remember };
    }

    const response = await axios.post(config.loginEndpoint, payload);
    const { token, refreshToken } = response.data;

    if (!token) {
      dispatch({
        type: Types.Initial,
        payload: initialState,
      });
    }

    const decoded = jwt<{ sub: string; tfaEnabledForUser?: boolean }>(token);

    if (config.TWO_FACTOR_AUTH_ENABLED) {
      dispatch({
        type: Types.LoginWithPassword,
        payload: {
          tfaToken: token,
          tfaEnabledForUser: decoded.tfaEnabledForUser,
        },
      });
    } else {
      LocalStorageManager.setToken(token);
      LocalStorageManager.setRefreshToken(refreshToken);

      const result = await client.query<Me>({ query: ME_QUERY });
      LocalStorageManager.setUser(result.data.me);

      dispatch({
        type: Types.LoginWithPassword,
        payload: {
          isAuthenticated: true,
          user: result.data.me,
        },
      });
    }
  };

  const loginWithAuthCode = async (code: string, remember: boolean) => {
    if (!config.TWO_FACTOR_AUTH_ENABLED) throw new Error('Feature is not active');

    const response = await axios.post(
      config.tfaCodeEndpoint,
      {
        code,
        remember,
      },
      { headers: { Authorization: `Bearer ${state.tfaToken}` } }
    );
    const { token } = response.data;

    LocalStorageManager.setToken(token);

    const result = await client.query<Me>({ query: ME_QUERY });
    LocalStorageManager.setUser(result.data.me);

    dispatch({
      type: Types.LoginWithCode,
      payload: {
        tfaToken: null,
        user: result.data.me,
      },
    });
  };

  const getQrCode = async (): Promise<Blob> => {
    if (!config.TWO_FACTOR_AUTH_ENABLED) throw new Error('Feature is not active');

    const response = await axios.get(config.tfaQrCodeEndpoint, {
      headers: { Authorization: `Bearer ${state.tfaToken}` },
      responseType: 'arraybuffer',
    });

    return new Blob([response.data]);
  };

  const logout = async () => {
    LocalStorageManager.removeToken();
    LocalStorageManager.removeUser();
    LocalStorageManager.removeRefreshToken();
    dispatch({ type: Types.Logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        loginWithPasswordAndEmail,
        loginWithAuthCode,
        getQrCode,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
