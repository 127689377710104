import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ButtonAnimate from 'components/animate/ButtonAnimate';
import { forwardRef } from 'react';

const MIconButton = forwardRef<HTMLButtonElement, IconButtonProps>(({ children, ...other }, ref) => (
  <ButtonAnimate>
    <IconButton ref={ref} {...other}>
      {children}
    </IconButton>
  </ButtonAnimate>
));

export default MIconButton;
