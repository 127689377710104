import { yupResolver } from '@hookform/resolvers/yup';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import { CodeInput, LoginInput } from 'types/ctms/Login';
import { SnackbarType } from 'enums/SnackbarType';
import { UserStatus } from 'generated/globalTypes';
import useLocales from 'hooks/useLocales';
import useSnackbar from 'hooks/useSnackbar';
import { useEffect, useRef, useState } from 'react';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import useAuth from '../../hooks/useAuth';
import config from 'config';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

export default function Login() {
  const { loginWithPasswordAndEmail, loginWithAuthCode, getQrCode, tfaToken, tfaEnabledForUser } = useAuth();
  const { translate } = useLocales();
  const { enqueueSnackbarWithTypeAndMessage } = useSnackbar();

  const imageRef = useRef<HTMLImageElement>(null);

  const [showPassword, setShowPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showQr, setShowQr] = useState<boolean>(false);

  const LoginSchema = Yup.object().shape({
    username: Yup.string().email(translate('validation.emailFormat')).required(translate('validation.email')),
    password: Yup.string().required(translate('validation.password')),
  });

  const CodeSchema = Yup.object().shape({
    code: Yup.string().required(translate('validation.code')),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginInput>({
    shouldUnregister: true,
    resolver: yupResolver(LoginSchema) as Resolver<LoginInput>,
  });

  const { handleSubmit: handleCodeSubmit, control: codeControl } = useForm<CodeInput>({
    shouldUnregister: true,
    resolver: yupResolver(CodeSchema) as Resolver<CodeInput>,
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleRememberMe = (e: React.SyntheticEvent<Element, Event>, newValue: boolean) => {
    setIsChecked(newValue);
  };

  const onLoginFormSubmit = async (data: LoginInput) => {
    setLoading(true);

    try {
      await loginWithPasswordAndEmail(data.username, data.password, isChecked);
    } catch (error) {
      if (error.response.data.status === UserStatus.SUSPENDED) {
        enqueueSnackbarWithTypeAndMessage({
          message: translate('snackbar.loginSuspended'),
          type: SnackbarType.WARNING,
        });
      } else if (error.response.data.status === UserStatus.INACTIVE) {
        enqueueSnackbarWithTypeAndMessage({
          message: translate('snackbar.loginInactive'),
          type: SnackbarType.ERROR,
        });
      } else {
        enqueueSnackbarWithTypeAndMessage({
          message: translate('snackbar.loginError'),
          type: SnackbarType.ERROR,
        });
      }
    }

    setLoading(false);
  };

  const onLoginCodeSubmit = async (data: CodeInput) => {
    setLoading(true);
    try {
      await loginWithAuthCode(data.code, isChecked);
    } catch (error) {
      enqueueSnackbarWithTypeAndMessage({
        message: translate('snackbar.loginError'),
        type: SnackbarType.ERROR,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const renderQrCode = async () => {
      if (!tfaEnabledForUser && tfaToken) {
        const response = await getQrCode();
        const url = URL.createObjectURL(response);
        if (!imageRef?.current) return;

        imageRef.current.src = url;
        imageRef.current.onload = () => URL.revokeObjectURL(url);
        setShowQr(true);
      }
    };

    if (config.TWO_FACTOR_AUTH_ENABLED) {
      renderQrCode();
    }
  }, [getQrCode, tfaEnabledForUser, tfaToken]);

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        if (!tfaToken) {
          handleSubmit(onLoginFormSubmit)();
        } else {
          handleCodeSubmit(onLoginCodeSubmit)();
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tfaToken]);

  return (
    <RootStyle title={translate('login')}>
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                {translate('login')}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>{translate('validation.typeDetailsBelow')}</Typography>
            </Box>
          </Stack>
          {!tfaToken && (
            <Stack spacing={3}>
              <Controller
                name="username"
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={translate('form.email')}
                    error={errors.username && true}
                    helperText={errors.username?.message}
                  />
                )}
              />

              <Controller
                name="password"
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type={showPassword ? 'text' : 'password'}
                    label={translate('form.password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword} edge="end">
                            <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={errors.password && true}
                    helperText={errors.password?.message}
                  />
                )}
              />
            </Stack>
          )}

          <Stack alignItems="center" justifyContent="center" sx={{ display: showQr ? 'flex' : 'none', mb: 4 }}>
            <img ref={imageRef} alt="qrcode" />
            <Typography sx={{ color: 'text.secondary' }} variant="subtitle2">
              {translate('form.readQrCode')}
            </Typography>
          </Stack>

          {tfaToken && (
            <Stack spacing={3}>
              <Controller
                name="code"
                defaultValue={''}
                control={codeControl}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label={translate('form.code')}
                    error={error ? true : false}
                    helperText={error?.message}
                  />
                )}
              />
            </Stack>
          )}

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <FormControlLabel
              control={<Checkbox onChange={(e, newValue) => handleRememberMe(e, newValue)} checked={isChecked} />}
              label={translate('form.rememberMe') as string}
            />
            {!tfaToken && (
              <Link component={RouterLink} variant="subtitle2" to="/password/forgot">
                {translate('forgotPassword.main')}
              </Link>
            )}
          </Stack>
          {!tfaToken && (
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              onClick={handleSubmit(onLoginFormSubmit)}
              disableFocusRipple
            >
              {translate('button.login')}
            </LoadingButton>
          )}
          {tfaToken && (
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              onClick={handleCodeSubmit(onLoginCodeSubmit)}
              disableFocusRipple
            >
              {translate('button.sendCode')}
            </LoadingButton>
          )}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
