import { useMutation } from '@apollo/client';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Iconify from 'components/Iconify';
import { NotificationType } from 'generated/globalTypes';
import { ListNotifications_notifications_items as Notification } from 'generated/ListNotifications';
import { UpdateNotification, UpdateNotificationVariables } from 'generated/UpdateNotification';
import { UPDATE_NOTIFICATION } from 'gql/Notification';
import useQueryParams from 'hooks/useQueryParams';
import useParticipantStudyTabs from 'hooks/useParticipantStudyTabs';
import useStudyTabs from 'hooks/useStudyTabs';
import { Link as RouterLink } from 'react-router-dom';
import { fToNow } from 'utils/formatTime';

const mapNotificationType = (notification: Notification): string => {
  let result = '';
  switch (notification.type) {
    case NotificationType.NOTIFY_ADMINS_AND_MANAGERS_ABOUT_UNPAID_INVOICES:
      result = `${notification.notificationInformation.sponsor} ${notification.notificationInformation.protocolNumber} vizsgálatban ${notification.notificationInformation.invoiceNumber} számla fizetési határideje lejárt.`;
      break;
    case NotificationType.NOTIFY_ADMINS_ABOUT_END_OF_SETTLEMENT_PERIOD:
      result = `${notification.notificationInformation.sponsor} ${notification.notificationInformation.protocolNumber} vizsgálatban ${notification.notificationInformation.siteName} vizsgálati helyen tekintetében az elszámolási időszak lejárt.`;
      break;
    case NotificationType.NOTIFY_DOCTORS_IMAGING_READY_WITH_RECIST:
      result = `${notification.notificationInformation.sponsor} ${notification.notificationInformation.protocolNumber} vizsgálatban felvétel elkészült. RECIST leletezés szükséges. Leletezésre ${notification.notificationInformation.deliveryDeadline} óra áll rendelkezésre.`;
      break;
    case NotificationType.NOTIFY_DOCTORS_IMAGING_READY:
      if (notification.notificationInformation.deliveryDeadline) {
        result = `${notification.notificationInformation.sponsor} ${notification.notificationInformation.protocolNumber} vizsgálatban felvétel elkészült. Leletezésre ${notification.notificationInformation.deliveryDeadline} óra áll rendelkezésre.`;
      } else {
        result = `${notification.notificationInformation.sponsor} ${notification.notificationInformation.protocolNumber} vizsgálatban felvétel elkészült.`;
      }
      break;
    case NotificationType.NOTIFY_OPERATOR_EXAMINATION_CREATED:
      result = `${notification.notificationInformation.sponsor} ${notification.notificationInformation.protocolNumber} vizsgálatban ${notification.notificationInformation.randomNumber} random számú páciensnek ${notification.notificationInformation.service} vizsgálat került kiírásra`;
      break;
    case NotificationType.NOTIFY_DOCTORS_IMAGING_READY_DEADLINE:
      result = `${notification.notificationInformation.sponsor} ${notification.notificationInformation.protocolNumber} vizsgálatban a lelet elkészítésére már csak 24 óra áll rendelkezésre`;
      break;
    case NotificationType.NOTIFY_PARTICIPANTS_SCHEDULED_EXAMINATION:
      result = `${notification.notificationInformation.sponsor} ${notification.notificationInformation.protocolNumber} vizsgálatban ${notification.notificationInformation.service} vizsgálat került beütemezésre. Vizsgálat időpontja ${notification.notificationInformation.examinationDate}`;
      break;
    case NotificationType.NOTIFY_OPERATOR_AND_COORDINATION_ADMIN_TO_UPLOAD_IMAGE:
      result = `${notification.notificationInformation.sponsor} ${notification.notificationInformation.protocolNumber} protokollszámú vizsgálatban vakosított leletet fel kell tölteni.`;
      break;
  }
  return result;
};

export default function NotificationItem({
  notification,
  handleClose,
}: {
  notification: Notification;
  handleClose: () => void;
}) {
  const { getTabName } = useStudyTabs();
  const { getTabName: getParticipantStudyTabName } = useParticipantStudyTabs();
  const { listQueryParams } = useQueryParams();

  const [updateNotification] = useMutation<UpdateNotification, UpdateNotificationVariables>(UPDATE_NOTIFICATION);

  const handleUpdate = async (payload: UpdateNotificationVariables) => {
    await updateNotification({ variables: { id: payload.id } });
  };

  const createLinkFromNotificationInformation = (notificationInformation: any): string => {
    if (notificationInformation.entity === 'participant-studies') {
      return `/participant-studies/${notificationInformation.studyId}/${getParticipantStudyTabName(
        notificationInformation.tab
      )}${listQueryParams(notificationInformation.params)}`;
    }
    if (notificationInformation.entity === 'studies') {
      return `/studies/${notificationInformation.studyId}/${getTabName(notificationInformation.tab)}${listQueryParams(
        notificationInformation.params
      )}`;
    }
    return '';
  };

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(!notification.seen && {
          bgcolor: 'action.selected',
        }),
      }}
      component={RouterLink}
      to={createLinkFromNotificationInformation(notification.notificationInformation)}
      onClick={() => {
        handleUpdate({ id: notification.id });
        handleClose();
      }}
    >
      <ListItemText
        primary={mapNotificationType(notification)}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.created)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}
