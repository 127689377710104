import { useQuery } from '@apollo/client';
import closeFill from '@iconify/icons-eva/close-fill';
import { Icon } from '@iconify/react';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { GetContactPerson, GetContactPersonVariables } from 'generated/GetContactPerson';
import { GET_CONTACT_PERSON } from 'gql/ContactPerson';
import useAuth from 'hooks/useAuth';
import useAuthorization from 'hooks/useAuthorization';
import useLocales from 'hooks/useLocales';
import useSettings from 'hooks/useSettings';
import { ContactPersonParentIds, ContactPersonProps } from 'types/ctms/ContactPersonTypes';
import ContactPersonForm from '../forms/ContactPersonForm';

export default function ContactPersonDialog({
  contactPersonParentIds,
  currentEntityId,
  open,
  onClose,
  onCreate,
  onUpdate,
}: ContactPersonProps): JSX.Element {
  const { themeMode } = useSettings();
  const { translate } = useLocales();
  const { user } = useAuth();
  const { canSeeAndEditNotes } = useAuthorization();

  const { data, loading } = useQuery<GetContactPerson, GetContactPersonVariables>(GET_CONTACT_PERSON, {
    variables: {
      id: currentEntityId ?? '',
      notesAllowed: canSeeAndEditNotes(user?.role),
    },
  });

  const contactPersonListedIds: ContactPersonParentIds = {
    croId: data?.contactPerson.cro?.id,
    sponsorId: data?.contactPerson.sponsor?.id,
    imagingVendorId: data?.contactPerson.imagingVendor?.id,
    payerId: data?.contactPerson.payer?.id,
    siteId: data?.contactPerson.site?.id,
  };

  return (
    <>
      {!loading && (
        <Dialog open={open} fullWidth={true} maxWidth={'md'} onClose={onClose}>
          <IconButton onClick={onClose} sx={{ position: 'absolute', right: 20, top: 10, zIndex: 2 }}>
            <Icon icon={closeFill} width={40} height={40} color={themeMode === 'dark' ? 'white' : 'black'} />
          </IconButton>
          <DialogContent>
            <Typography variant="h4" sx={{ mb: 3 }}>
              {currentEntityId ? translate('editContactPerson') : translate('addContactPerson')}
            </Typography>
            <ContactPersonForm
              onCreate={onCreate}
              onUpdate={onUpdate}
              contactPersonParentIds={contactPersonParentIds ?? contactPersonListedIds}
              currentEntity={data?.contactPerson}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
