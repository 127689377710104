type ParticipantStudyViewTabNames = 'overview' | 'patients' | 'examinations';

const TabNameToIndex: { [key: string]: string } = {
  overview: '1',
  patients: '2',
  examinations: '3',
};

const TabIndexToTabName: { [key: string]: ParticipantStudyViewTabNames } = {
  '1': 'overview',
  '2': 'patients',
  '3': 'examinations',
};

const getTabName = (index: string): ParticipantStudyViewTabNames => {
  if (index && index in TabIndexToTabName) return TabIndexToTabName[index];
  return TabIndexToTabName['1'];
};

const getTabIndex = (name?: string): string => {
  if (name && name in TabNameToIndex) return TabNameToIndex[name];
  return TabNameToIndex.overview;
};

const useParticipantStudyTabs = () => {
  return {
    getTabIndex,
    getTabName,
  };
};

export default useParticipantStudyTabs;
