import { enUS, huHU } from '@mui/material/locale';
import { loadMomentLocale } from 'i18n/loadLocaleModules';
import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const LANGS = [
  {
    label: 'Magyar',
    value: 'hu',
    systemValue: huHU,
    icon: '/icons/ic_flag_hu.svg',
  },
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/icons/ic_flag_en.svg',
  },
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation('translations');
  const langStorage = localStorage.getItem('i18nextLng');

  const currentLang = useMemo(() => {
    return LANGS.find((_lang) => _lang.value === langStorage) || LANGS[0];
  }, [langStorage]);

  const onChangeLang = (lang: string) => {
    loadMomentLocale(lang);
    i18n.changeLanguage(lang);
    moment.locale(lang);
  };

  return {
    onChangeLang,
    translate,
    currentLang,
    allLang: LANGS,
  };
}
