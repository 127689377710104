import config from 'config/config';
import { UserRole } from 'generated/globalTypes';
import { useCallback, useMemo } from 'react';
import useAuth from './useAuth';

type AuthorizationMap = { [key: string]: { [key: string]: UserRole } };

const authorization: AuthorizationMap = {
  canSeeCosts: {
    ADMIN: UserRole.ADMIN,
    SYSTEM_ADMIN: UserRole.SYSTEM_ADMIN,
    FINANCIAL_ADMIN: UserRole.FINANCIAL_ADMIN,
    MANAGER: UserRole.MANAGER,
    STARTUP_ADMIN: UserRole.STARTUP_ADMIN,
  },
  canSeeAndEditNotes: {
    MANAGER: UserRole.MANAGER,
    ADMIN: UserRole.ADMIN,
    SYSTEM_ADMIN: UserRole.SYSTEM_ADMIN,
  },
};

const useAuthorization = () => {
  const { user } = useAuth();

  const canSeeProposalServiceCosts = useCallback((role: UserRole | null | undefined) => {
    if (!role) return false;
    return authorization.canSeeCosts[role as string] ? true : false;
  }, []);

  const canSeeAndEditNotes = useCallback((role: UserRole | null | undefined) => {
    if (!role) return false;
    return authorization.canSeeAndEditNotes[role as string] ? true : false;
  }, []);

  const isSystemAdmin = useMemo(() => {
    return user?.role === UserRole.SYSTEM_ADMIN;
  }, [user?.role]);

  const isDoctor = useMemo<boolean>(() => user?.role === UserRole.DOCTOR, [user?.role]);

  const canAccessComplexCalendarView = useMemo(() => {
    if (!user?.role) return false;
    return [...config.managerAdminSys, UserRole.COORDINATION_ADMIN].includes(user.role);
  }, [user?.role]);

  const canAccessCalendarView = [...config.managerAdminSys, ...config.operatorDoctor, UserRole.COORDINATION_ADMIN];

  const hasAdminPrivileges = useMemo(() => config.managerAdminSys.includes(user?.role as UserRole), [user?.role]);

  return {
    canSeeProposalServiceCosts,
    canSeeAndEditNotes,
    isSystemAdmin,
    isDoctor,
    canAccessComplexCalendarView,
    canAccessCalendarView,
    hasAdminPrivileges,
  };
};

export default useAuthorization;
