import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import closeFill from '@iconify/icons-eva/close-fill';
import { Icon } from '@iconify/react';
import { MIconButton } from './@material-extend';
import { useSnackbar } from 'notistack';
import useLocales from 'hooks/useLocales';
import { DeleteEntityDialogProps } from 'types/ctms/DeleteEntityType';

export default function DeleteEntityDialog({
  id,
  open,
  onClose,
  removeEntity,
  message,
}: DeleteEntityDialogProps): JSX.Element {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { translate } = useLocales();

  const handleDelete = async () => {
    try {
      await removeEntity(id);
      onClose();
    } catch (error) {
      return;
    }

    enqueueSnackbar(translate('snackbar.success'), {
      variant: 'success',
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      ),
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ mb: 3 }} id="delete-user-dialog-title">
        {translate('modal.areYouSure')}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>{translate('modal.irreversibleDelete')}</DialogContentText>

        <DialogContentText variant="h6" style={{ color: 'red' }}>
          {message}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          {translate('cancel')}
        </Button>

        <Button variant="contained" onClick={handleDelete} color="error">
          {translate('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
