import { gql } from '@apollo/client';
import { CONTACT_PERSON_FRAGMENT, FULL_NAME_FRAGMENT } from 'fragments/Fragments';

export const CONTACT_PERSONS_QUERY = gql`
  ${FULL_NAME_FRAGMENT}
  ${CONTACT_PERSON_FRAGMENT}

  query ListContactPersons($args: ContactPersonListingArg!) {
    contactPersons(listArg: $args) {
      items {
        ...BaseContactPerson
      }
      total
      downloadToken
    }
  }
`;

export const CREATE_CONTACT_PERSON = gql`
  ${FULL_NAME_FRAGMENT}
  ${CONTACT_PERSON_FRAGMENT}

  mutation CreateContactPerson($contactPerson: CreateContactPersonInput!) {
    createContactPerson(contactPerson: $contactPerson) {
      ...BaseContactPerson
    }
  }
`;

export const UPDATE_CONTACT_PERSON = gql`
  ${FULL_NAME_FRAGMENT}
  ${CONTACT_PERSON_FRAGMENT}

  mutation UpdateContactPerson($contactPerson: UpdateContactPersonInput!) {
    updateContactPerson(contactPerson: $contactPerson) {
      ...BaseContactPerson
    }
  }
`;

export const CONTACT_PERSON_DELETE = gql`
  mutation DeleteContactPerson($id: String!) {
    deleteContactPerson(id: $id) {
      id
    }
  }
`;

export const GET_CONTACT_PERSON = gql`
  ${FULL_NAME_FRAGMENT}
  ${CONTACT_PERSON_FRAGMENT}

  query GetContactPerson($id: String!, $notesAllowed: Boolean!) {
    contactPerson(id: $id) {
      ...BaseContactPerson
      personalEmail
      personalPhone
      notes @include(if: $notesAllowed)
    }
  }
`;
