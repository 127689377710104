import Container from "@mui/material/Container";
import ContactPersonsList from 'components/contcat-person/ContactPersonsList';
import Page from 'components/Page';
import useLocales from 'hooks/useLocales';
import useSettings from 'hooks/useSettings';

export default function ContactPersonsListPage() {
  const { translate } = useLocales();
  const { themeStretch } = useSettings();

  return (
    <Page title={translate('contactPersons')}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <ContactPersonsList />
      </Container>
    </Page>
  );
}
