import { AxiosResponse } from 'axios';
import config from 'config';
import axios from './axios';

export const triggerFileDownload = ({
  response,
  contentType,
  fileName,
}: {
  response: AxiosResponse;
  contentType?: string;
  fileName?: string;
}) => {
  const disposition = response.headers['content-disposition'];
  let name: string;
  if (disposition) {
    name = disposition.substring(disposition.indexOf('"') + 1, disposition.lastIndexOf('"'));
  } else if (fileName) {
    name = fileName;
  } else {
    name = 'file';
  }

  const type = contentType ? contentType : response.headers['content-type'];

  const blob = new Blob([response.data], { type });
  const downloadUrl = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = downloadUrl;
  a.download = name;
  document.body.appendChild(a);
  a.click();
};

export const handlePdfExport = async (entity: string, id: string) => {
  const response = await axios.get(`${config.exportEndpoint}/pdf`, {
    params: { entity, id },
    responseType: 'arraybuffer',
  });
  triggerFileDownload({ response });
};

export const handleExcelEntityExport = async (entity: string, id: string) => {
  const response = await axios.get(`${config.exportEndpoint}`, {
    params: { entity, id },
    responseType: 'arraybuffer',
  });
  triggerFileDownload({ response });
};

export const handleProposalExcelEntityExport = async (id: string) => {
  const response = await axios.get(`${config.exportEndpoint}/proposal-excel`, {
    params: { id },
    responseType: 'arraybuffer',
  });
  triggerFileDownload({ response });
};

export const handleExcelListExportWithFilterToken = async (token: string) => {
  const response = await axios.get(`${config.exportEndpoint}`, {
    params: { token },
    responseType: 'arraybuffer',
  });
  triggerFileDownload({ response });
};
