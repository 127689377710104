import { useMutation, useQuery } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { GridActionsCellItem, GridColumns } from '@mui/x-data-grid';
import ContactPersonDialog from 'components/contcat-person/ContactPersonDialog';
import DeleteEntityDialog from 'components/DeleteEntityDialog';
import ServerPaginatedDataGrid from 'components/grid/ServerPaginatedDataGrid';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Iconify from 'components/Iconify';
import ListingPageSearchInputs from 'components/ListingPageSearchInputs';
import config from 'config';
import { SearchFieldAll, SearchFieldType } from 'enums/SearchEnums';
import { SnackbarType } from 'enums/SnackbarType';
import { CreateContactPerson, CreateContactPersonVariables } from 'generated/CreateContactPerson';
import { DeleteContactPerson } from 'generated/DeleteContactPerson';
import {
  ContactPersonType,
  CreateContactPersonInput,
  OrderType,
  UpdateContactPersonInput,
  UserRole,
} from 'generated/globalTypes';
import { ListContactPersons, ListContactPersonsVariables } from 'generated/ListContactPersons';
import { UpdateContactPerson, UpdateContactPersonVariables } from 'generated/UpdateContactPerson';
import {
  CONTACT_PERSONS_QUERY,
  CONTACT_PERSON_DELETE,
  CREATE_CONTACT_PERSON,
  UPDATE_CONTACT_PERSON,
} from 'gql/ContactPerson';
import useAuth from 'hooks/useAuth';
import useDebounce from 'hooks/useDebounce';
import useLocales from 'hooks/useLocales';
import useSnackbar from 'hooks/useSnackbar';
import React, { useState } from 'react';
import { handleExcelListExportWithFilterToken } from 'utils/fileDownload';
import formatFullName from 'utils/formatFullName';
import { ContactPersonParentIds, ContactPersonsListProps } from 'types/ctms/ContactPersonTypes';
import { SearchFieldConfig } from 'types/ctms/SearchTypes';

const MergedSearchField = { ...SearchFieldAll, ...ContactPersonType };

export default function ContactPersonsList({ contactPersonParentIds }: ContactPersonsListProps) {
  const { translate } = useLocales();
  const { user } = useAuth();
  const { enqueueSnackbarWithTypeAndMessage } = useSnackbar();

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [currentId, setCurrentId] = useState<string>('');
  const [isContactPersonDialogOpen, setContactPersonDialogOpen] = useState(false);
  const [currentEntityId, setCurrentEntityId] = useState<string | null>(null);
  const [searchName, setSearchName] = useState<string>('');
  const [searchEmail, setSearchEmail] = useState<string>('');
  const [searchContactFor, setSearchContactFor] = useState<string>('');
  const [searchType, setSearchType] = useState<string>(MergedSearchField.ALL);

  const debouncedSearchName = useDebounce(searchName, 600);
  const debouncedSearchEmail = useDebounce(searchEmail, 600);
  const debouncedSearchContactFor = useDebounce(searchContactFor, 600);

  const searchFields: SearchFieldConfig[] = [
    {
      label: translate('search.name'),
      type: SearchFieldType.TextField,
      setter: setSearchName,
      value: searchName,
    },
    {
      label: translate('search.email'),
      type: SearchFieldType.TextField,
      setter: setSearchEmail,
      value: searchEmail,
    },
    {
      label: translate('search.connectedEntity'),
      type: SearchFieldType.TextField,
      setter: setSearchContactFor,
      value: searchContactFor,
    },
    {
      label: translate('search.type'),
      type: SearchFieldType.SelectField,
      setter: setSearchType,
      value: searchType,
      options: MergedSearchField,
    },
  ];

  const columns: GridColumns = [
    {
      flex: 0.5,
      field: 'name',
      headerName: translate('form.name'),
      minWidth: 200,
      valueGetter: (params) => formatFullName(params.row.name),
    },
    {
      field: 'phone',
      headerName: translate('form.phone'),
      flex: 0.5,
      minWidth: 200,
      sortable: false,
    },
    {
      field: 'email',
      headerName: translate('form.email'),
      flex: 0.5,
      minWidth: 200,
      sortable: false,
    },
    {
      field: 'type',
      headerName: translate('form.type'),
      flex: 0.5,
      minWidth: 200,
      sortable: false,
      valueGetter: (params) => translate(`enums.contactPersonType.${params.row.type}`),
    },
    {
      field: 'actions',
      headerName: translate('form.actions'),
      type: 'actions',
      width: 100,
      getActions: (param) => {
        const returnedActions = [
          <GridActionsCellItem
            key="edit"
            icon={<EditIcon />}
            label={translate('form.edit')}
            onClick={() => {
              setCurrentEntityId(param.id.toString());
              handleOpenContactPersonDialog();
            }}
          />,
        ];
        if (user?.role && [UserRole.ADMIN, UserRole.SYSTEM_ADMIN].includes(user.role)) {
          returnedActions.push(
            <GridActionsCellItem
              key="delete"
              icon={<DeleteIcon />}
              label={translate('form.delete')}
              onClick={() => handleOpenDeleteDialogue(param.id.toString())}
            />
          );
        }
        return returnedActions;
      },
    },
  ];

  if (!contactPersonParentIds) {
    columns.splice(1, 0, {
      field: 'contactFor',
      headerName: translate('form.connectedEntity'),
      flex: 0.5,
      minWidth: 200,
      sortable: false,
      valueGetter: (params) => {
        if (params.row.sponsor?.name) return params.row.sponsor.name;
        if (params.row.cro?.name) return params.row.cro.name;
        if (params.row.payer?.name) return params.row.payer.name;
        if (params.row.site?.name) return params.row.site.name;
        if (params.row.imagingVendor?.name) return params.row.imagingVendor.name;
      },
    });
  }

  const getParentId = (contactPersonParentIds: ContactPersonParentIds) => {
    for (const property in contactPersonParentIds) {
      if (!Object.prototype.hasOwnProperty.call(contactPersonParentIds, property)) {
        continue;
      }
      return contactPersonParentIds[property as keyof typeof contactPersonParentIds];
    }
  };

  const parentId = contactPersonParentIds ? getParentId(contactPersonParentIds) : null;

  const listArgs = {
    page: config.defaultPage,
    take: config.defaultSize,
    order: {
      name: OrderType.ASC,
    },
    filter: {
      contactForId: parentId,
      contactFor: debouncedSearchContactFor,
      email: debouncedSearchEmail,
      name: debouncedSearchName,
      type: searchType !== MergedSearchField.ALL ? (searchType as ContactPersonType) : null,
    },
  };

  const { data, loading, fetchMore, refetch } = useQuery<ListContactPersons, ListContactPersonsVariables>(
    CONTACT_PERSONS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        args: listArgs,
      },
    }
  );

  const [addContactPerson] = useMutation<CreateContactPerson, CreateContactPersonVariables>(CREATE_CONTACT_PERSON);
  const [updateContactPerson] = useMutation<UpdateContactPerson, UpdateContactPersonVariables>(UPDATE_CONTACT_PERSON);

  const handleCreate = async (payload: CreateContactPersonInput) => {
    try {
      await addContactPerson({
        variables: {
          contactPerson: payload,
        },
      });

      await refetch();
      handleCloseContactPersonDialog();

      enqueueSnackbarWithTypeAndMessage({
        message: translate('snackbar.successAdd'),
        type: SnackbarType.SUCCESS,
      });
    } catch (error: any) {
      enqueueSnackbarWithTypeAndMessage({
        message: translate('snackbar.error'),
        type: SnackbarType.ERROR,
      });
    }
  };

  const handleUpdate = async (payload: UpdateContactPersonInput) => {
    try {
      await updateContactPerson({
        variables: {
          contactPerson: payload,
        },
      });

      await refetch();
      handleCloseContactPersonDialog();

      enqueueSnackbarWithTypeAndMessage({
        message: translate('snackbar.successEdit'),
        type: SnackbarType.SUCCESS,
      });
    } catch (error: any) {
      enqueueSnackbarWithTypeAndMessage({
        message: translate('snackbar.error'),
        type: SnackbarType.ERROR,
      });
    }
  };

  const handleCloseContactPersonDialog = () => {
    setContactPersonDialogOpen(false);
    setCurrentEntityId(null);
  };

  const handleOpenContactPersonDialog = () => {
    setContactPersonDialogOpen(true);
  };

  const [deleteContactPerson] = useMutation<DeleteContactPerson>(CONTACT_PERSON_DELETE, {
    update(cache, el) {
      cache.evict({ id: cache.identify({ __typename: 'ContactPerson', id: el.data?.deleteContactPerson.id }) });
    },
  });

  const handleCloseDeleteDialog = () => {
    setIsDeleteOpen(false);
  };

  const handleDeleteEntity = async () => {
    try {
      await deleteContactPerson({
        variables: {
          id: currentId,
        },
      });
      refetch();
    } catch (error: any) {
      let message = '';
      if (error.message.includes('delete_operation_failed_entity_has_required_relations')) {
        message = 'snackbar.deleteOperationFailedEntityHasRequiredRelations';
      } else {
        message = 'snackbar.error';
      }
      enqueueSnackbarWithTypeAndMessage({
        message: translate(message),
        type: SnackbarType.ERROR,
      });
      throw error;
    }
  };

  const handleOpenDeleteDialogue = (id: string) => {
    setCurrentId(id);
    setIsDeleteOpen(true);
  };

  const getBreadCrumbLinks = () => {
    if (contactPersonParentIds) {
      return [{ name: '' }];
    }
    return [{ name: translate('masterData') }, { name: translate('contactPersons') }];
  };

  return (
    <>
      <HeaderBreadcrumbs
        heading={translate('contactPersons')}
        sx={{ mt: contactPersonParentIds ? 6 : 0, mb: 2 }}
        links={getBreadCrumbLinks()}
        action={
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Button
              variant="contained"
              size={'large'}
              onClick={() => handleExcelListExportWithFilterToken(data?.contactPersons.downloadToken as string)}
            >
              {translate('exportAsExcel')}
            </Button>
            {contactPersonParentIds && (
              <Button
                variant="contained"
                startIcon={<Iconify icon={'eva:plus-fill'} />}
                onClick={handleOpenContactPersonDialog}
              >
                {translate('newContactPerson')}
              </Button>
            )}
          </Stack>
        }
      />

      {!contactPersonParentIds && (
        <Stack direction="row" sx={{ mb: 2 }} width="100%">
          <ListingPageSearchInputs searchFields={searchFields} />
        </Stack>
      )}
      <Card>
        <ServerPaginatedDataGrid
          rows={data?.contactPersons.items ?? []}
          total={data?.contactPersons.total ?? 0}
          columns={columns}
          fetchMore={fetchMore}
          loading={loading}
          listArgs={listArgs}
        />
      </Card>
      <DeleteEntityDialog
        id={currentId}
        open={isDeleteOpen}
        onClose={handleCloseDeleteDialog}
        removeEntity={handleDeleteEntity}
      />

      {isContactPersonDialogOpen && (
        <ContactPersonDialog
          contactPersonParentIds={contactPersonParentIds}
          open={isContactPersonDialogOpen}
          onClose={handleCloseContactPersonDialog}
          onCreate={handleCreate}
          onUpdate={handleUpdate}
          currentEntityId={currentEntityId}
        />
      )}
    </>
  );
}
