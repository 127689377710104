import { gql } from '@apollo/client';

export const NOTIFICATIONS_QUERY = gql`
  query ListNotifications($onlyUnseens: Boolean!) {
    notifications(onlyUnseens: $onlyUnseens) {
      items {
        id
        type
        notificationInformation
        seen
        created
      }
      total
    }
  }
`;

export const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification($id: String!) {
    updateNotification(id: $id) {
      id
    }
  }
`;
